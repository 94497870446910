import React, { FC, useEffect, useRef, useState } from "react";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import AnimateHeight from "react-animate-height";

const DURATION = 300;
/**
 * Header used in all panels
 * @param param0 title
 * @returns the graphical component
 */
const Panel: FC<{
  title: JSX.Element | string;
  initiallyDeployed?: boolean;
  children: React.ReactNode;
}> = ({ title, initiallyDeployed, children }) => {
  const [isDeployed, setIsDeployed] = useState(initiallyDeployed || false);
  const dom = useRef<HTMLDivElement>(null);

  /**
   * Handle the panel is expanded or contracted
   */
  useEffect(() => {
    if (isDeployed)
      setTimeout(() => {
        if (dom.current)
          dom.current.parentElement!.scrollTo({
            top: dom.current.offsetTop - 0,
            behavior: "smooth",
          });
      }, DURATION);
  }, [isDeployed]);

  /**
   * Draw the component
   */
  return (
    <div className="panel" ref={dom}>
      <h2 onClick={() => setIsDeployed((v) => !v)}>
        {title}{" "}
        <button type="button">
          {isDeployed ? <MdExpandLess /> : <MdExpandMore />}
        </button>
      </h2>
      <AnimateHeight duration={DURATION} height={isDeployed ? "auto" : 0}>
        <div className="children">{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default Panel;
