import { useState } from "react";

export default function useRightPanel() {
  const [isOpenRightPanel, setisOpenRightPanel] = useState(false);

  const toggleRightPanel = () => {
    setisOpenRightPanel(!isOpenRightPanel);
  };

  return {
    isOpenRightPanel,
    toggleRightPanel,
  };
}
