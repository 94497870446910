import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import * as API from "../../services/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePasswordPage: FC = () => {
  const { accessToken, viewer, Logout } = useAuth();
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [passwordMode, setPasswordMode] = useState<boolean>(true);

  const validatePassword = (pw1: string, pw2: string) => {
    if (pw1.length < 8) {
      return "Senha com menos de 8 caracteres.";
    }

    if (!/\d/.test(pw1)) {
      return "Senha não contém ao menos 1 número";
    }

    if (!/[A-Z]/.test(pw1)) {
      return "Senha não contém ao menos 1 letra maiúscula";
    }

    if (!/[a-z]/.test(pw1)) {
      return "Senha não contém ao menos 1 letra minúscula";
    }

    if (pw1 !== pw2) {
      return "As senhas não conferem.";
    }
    return null;
  };

  const handleSubmit = async () => {
    let invalidPass = validatePassword(password1, password2);
    if (!invalidPass && viewer && accessToken) {
      const success = await API.setPassword(viewer, password1, accessToken);
      if (success) {
        toast.success("Senha alterada com sucesso.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
        Logout();
      } else {
        toast.error("Algo deu errado", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "light",
        });
      }
    } else {
      setPassword1("");
      setPassword2("");
      toast.warning(invalidPass, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    setPasswordMode(window.history.state["noPassword"] ? false : true);
  }, []);

  return (
    <>
      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper blue-grey darken-4">
            <div className="brand-logo hide-on-small-only">
              <img
                src="/images/logo-neuroredes-header.png"
                className="img-responsive"
                alt="Neuroredes"
              />
            </div>
            {passwordMode ? (
              <ul id="nav-mobile" className="right">
                <li>
                  <Link to="/analises">
                    <span style={{ color: "white" }}>Análises</span>
                  </Link>
                </li>
                <li className="active">
                  <a href="/trocarSenha" style={{ color: "white" }}>
                    Alterar senha
                  </a>
                </li>
                <li>
                  <a href="/" onClick={Logout} style={{ color: "white" }}>
                    Sair
                  </a>
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
        </nav>
      </div>

      {passwordMode ? (
        <div className="login-info right-align">
          Autenticado como: {viewer?.nome}
        </div>
      ) : (
        <></>
      )}

      <div className="row">
        <div className="col s1"></div>
        <div className="col s11 left-align">
          <h1>
            Cadastrar senha <br />
            {passwordMode ? (
              <Link to="/analises">
                <div className="waves-effect white blue-text text-darken-4 btn">
                  <i className="material-icons left">arrow_back</i>
                  Voltar para a lista de pesquisas
                </div>
              </Link>
            ) : (
              <></>
            )}
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col s2"></div>
        <div className="col s8">
          <div className="row">
            <div className="input-field col s12">
              <input
                type="text"
                id="nome"
                name="nome"
                className="nome"
                value={viewer?.nome}
                disabled
              />
              <label htmlFor="nome" className="active">
                <i className="material-icons left">person</i>
                Nome
              </label>
            </div>
            <div className="input-field col s12">
              <input
                type="text"
                id="email"
                name="email"
                className="email validate"
                value={viewer?.email}
                disabled
              />
              <label htmlFor="email" className="active">
                <i className="material-icons left">mail_outline</i>
                E-mail
              </label>
            </div>
            <div className="input-field col s12">
              <input
                type="password"
                id="pass"
                name="pass"
                className="pass validate"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={(e) => setPassword1(e.target.value)}
                value={password1}
                required
              />
              <label htmlFor="pass" className="">
                <i className="material-icons left">vpn_key</i>
                Senha
              </label>
              <br />
              <span
                className="helper-text"
                data-error="A senha deve ter ao menos 8 caracteres e deve conter ao menos um número, uma letra maiúscula e uma letra minúscula"
                data-success=""
              ></span>
            </div>
            <div className="input-field col s12">
              <input
                type="password"
                id="re_pass"
                name="re_pass"
                className="re_pass"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={(e) => setPassword2(e.target.value)}
                value={password2}
                required
              />
              <label htmlFor="re_pass" className="">
                <i className="material-icons left">vpn_key</i>
                Redigite a senha
              </label>
              <br />
              <span
                className="helper-text"
                data-error="As senhas digitadas não coincidem."
                data-success=""
              ></span>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col s12 center-align">
              <button
                className="waves-effect waves-light blue darken-4 btn"
                onClick={handleSubmit}
              >
                <i className="material-icons left">check</i>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};

export default ChangePasswordPage;
