import React, { ReactNode } from "react";

interface RightPanelType {
  children?: ReactNode;
  isOpenRightPanel: boolean;
  toggleRightPanel: () => void;
}

export default function RightPanel(props: RightPanelType) {
  return (
    <>
      {!props.isOpenRightPanel && (

        <div onClick={(e) => e.stopPropagation()} className="right-panel-content hoverable">
          {props.children}
        </div>

      )
      }
    </>
  );
}
