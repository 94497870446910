import { FC, useEffect, useMemo, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { values, keyBy, mapValues, omit, pickBy } from "lodash";
import { MdGroupWork } from "react-icons/md";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { FiltersState, Question, SynchronyStyle } from "./types";
import Panel from "./Panel";

/**
 * Panel used the filter the synchrony
 * @param param0
 * @returns the graphical element
 */
const SynchronyPanel: FC<{
  questions: Record<string,Question> |null;
  synchronyStyle: SynchronyStyle[];
  filters: FiltersState;
  currentQuestion: string | null;
  edgesPerSynchrony: Record<string, number>;
  setFiltersState: (filtersState: FiltersState) => void;
}> = ({
  questions,
  synchronyStyle,
  filters,
  currentQuestion,
  edgesPerSynchrony,
  setFiltersState,
}) => {
    const sigma = useSigma();
    const graph = sigma.getGraph();

    /**
     * Calculate the number of edges for each synchrony. It has to be update everytime a new question is selected
     */
    // const edgesPerSynchrony = useMemo(() => {
    //   const index: Record<string, number> = {};
    //   if (!questions || !currentQuestion || !questions[currentQuestion]) return index;

    //   questions[currentQuestion].edges
    //     .filter((edge) => edge.synchrony !== 0)
    //     .forEach(
    //       (edge) =>
    //       (index[String(edge.synchrony)] =
    //         (index[String(edge.synchrony)] || 0) + 1)
    //     );
    //   return index;
    // }, [currentQuestion]);

    /**
     * Calculate the highest number of edges among all the synchrony
     */
    const maxEdgesPerSynchrony = useMemo(
      () => Math.max(...values(edgesPerSynchrony)),
      [edgesPerSynchrony]
    );

    /**
     * Calculate the number of synchrony
     */
    const visibleSynchronyCount = useMemo(
      () => Object.keys(filters.synchrony).length,
      [filters,edgesPerSynchrony]
    );

    /**
     * Toggle the synchrony in the filter
     * @param synchrony_key key to be toggle
     */
    function toggleSynchrony(synchrony_key: string) {
      setFiltersState({
        ...filters,
        synchrony: filters.synchrony[synchrony_key]
          ? omit(filters.synchrony, synchrony_key)
          : { ...filters.synchrony, [synchrony_key]: true },
      });
    }

    /**
     * Set the synchrony state in the filter
     * @param synchrony
     */
    function setSynchrony(synchrony: Record<string, boolean>) {
      setFiltersState({
        ...filters,
        synchrony: synchrony,
      });
    }

    /**
     * Set the number of visible edges per synchrony
     */
    const [visibleEdgesPerSynchrony, setVisibleEdgesPerSynchrony] =
      useState<Record<string, number>>(edgesPerSynchrony);
    useEffect(() => {
      // To ensure the graphology instance has up to data "hidden" values for
      // nodes, we wait for next frame before reindexing. This won't matter in the
      // UX, because of the visible nodes bar width transition.
      requestAnimationFrame(() => {
        const index: Record<string, number> = {};
        graph.forEachEdge(
          (_, { synchrony, hidden }) =>
            !hidden && (index[synchrony] = (index[synchrony] || 0) + 1)
        );
        setVisibleEdgesPerSynchrony(index);
      });
    }, [filters,edgesPerSynchrony]);

    /**
     * Draw the graphical element
     */
    return (
      // Draw panel header
      <Panel
        title={
          <>
            <MdGroupWork className="text-muted" /> Conexões
          </>
        }
      >
        {/* 'Select all' or 'Unselect all' button */}

        <p className="buttons">
          <button
            className="btn-small"
            style={{ backgroundColor: "rgba(0,0,0,0)", color: "grey", border: "1px solid #eee" }}
            onClick={() =>
              setSynchrony(mapValues(keyBy(synchronyStyle, "key"), () => true))
            }
          >
            <AiOutlineCheckCircle /> Todos
          </button>{" "}

          <button className="btn-small"
            style={{ backgroundColor: "rgba(0,0,0,0)", color: "grey", border: "1px solid #eee" }}
            onClick={() => setSynchrony({})}>
            <AiOutlineCloseCircle /> Nenhum
          </button>
        </p>

        <hr style={{ border: "1px solid #eee" }} />

        {/* 'Select only sync' button */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Síncrono</h2>

          <button
            className="btn-small"
            style={{ backgroundColor: "rgba(0,0,0,0)", color: "grey", border: "1px solid #eee" }}
            onClick={() =>
              setSynchrony(
                mapValues(
                  keyBy(
                    pickBy(synchronyStyle, (synchrony) => {
                      return synchrony.type === "Síncrono";
                    }),
                    "key"
                  ),
                  () => true
                )
              )
            }
          >
            <AiOutlineCheckCircle /> Apenas Síncronos
          </button>{" "}
        </div>

        {/* Draw sync list */}
        <ul>
          {Object.keys(
            pickBy(synchronyStyle, (synchrony) => {
              return synchrony.type === "Síncrono";
            })
          ).map((key, index) => {
            const edgesCount = edgesPerSynchrony[key];
            const visibleEdgesCount = visibleEdgesPerSynchrony[key] || 0;
            return (
              <li
                className="caption-row"
                key={key}
                title={`
                    ${edgesCount
                    ? edgesCount +
                    (edgesCount > 1 ? " Conexões" : " Conexão") +
                    (visibleEdgesCount !== edgesCount
                      ? `( Exibindo ${visibleEdgesCount} )`
                      : "")
                    : "" + synchronyStyle[key as any].label
                  }`}
              >
                <input
                  type="checkbox"
                  checked={filters.synchrony[key] || false}
                  onChange={() => toggleSynchrony(key)}
                  id={`synchrony-${key}`}
                />
                <label htmlFor={`synchrony-${key}`}>
                  {visibleEdgesCount ? (
                    <span
                      className="circle"
                      style={{ background: synchronyStyle[key as any].color, borderColor: synchronyStyle[key as any].color }}
                    />
                  )
                  :(
                  <span
                    className="circle"
                    style={{ background: "white", borderColor: "#eee" }}
                  />
                  )
                 }
                  <div className="node-label">
                    <span>{synchronyStyle[key as any].label}</span>
                    <div
                      className="bar"
                      style={{
                        width: (100 * edgesCount) / maxEdgesPerSynchrony + "%",
                      }}
                    >
                      <div
                        className="inside-bar"
                        style={{
                          width: (100 * visibleEdgesCount) / edgesCount + "%",
                        }}
                      />
                    </div>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>

        <hr style={{ border: "1px solid #eee" }} />

        {/* 'Select only async' button */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Assíncrono</h2>

          <button
            className="btn-small"
            style={{ backgroundColor: "rgba(0,0,0,0)", color: "grey", border: "1px solid #eee" }}
            onClick={() =>
              setSynchrony(
                mapValues(
                  keyBy(
                    pickBy(synchronyStyle, (synchrony) => {
                      return synchrony.type === "Assíncrono";
                    }),
                    "key"
                  ),
                  () => true
                )
              )
            }
          >
            <span style={{ whiteSpace: "nowrap" }}><AiOutlineCheckCircle /> Apenas assíncronos</span>
          </button>{" "}
        </div>

        {/* Draw async list */}
        <ul>
          {Object.keys(
            pickBy(synchronyStyle, (synchrony) => {
              return synchrony.type === "Assíncrono";
            })
          ).map((key, index) => {
            const edgesCount = edgesPerSynchrony[key];
            const visibleEdgesCount = visibleEdgesPerSynchrony[key] || 0;

            return (
              <li
                className="caption-row"
                key={key}
                title={`
                    ${edgesCount
                    ? edgesCount +
                    (edgesCount > 1 ? " Conexões" : " Conexão") +
                    (visibleEdgesCount !== edgesCount
                      ? `( Exibindo ${visibleEdgesCount} )`
                      : "")
                    : "" + synchronyStyle[key as any].label
                  }`}
              >
                <input
                  type="checkbox"
                  checked={filters.synchrony[key] || false}
                  onChange={() => toggleSynchrony(key)}
                  id={`synchrony-${key}`}
                />
                <label htmlFor={`synchrony-${key}`}>
                {visibleEdgesCount ? (
                    <span
                      className="circle"
                      style={{ background: synchronyStyle[key as any].color, borderColor: synchronyStyle[key as any].color }}
                    />
                  )
                  :(
                  <span
                    className="circle"
                    style={{ background: "white", borderColor: "#eee" }}
                  />
                  )
                 }
                  <div className="node-label">
                    <span>{synchronyStyle[key as any].label}</span>
                    <div
                      className="bar"
                      style={{
                        width: (100 * edgesCount) / maxEdgesPerSynchrony + "%",
                      }}
                    >
                      <div
                        className="inside-bar"
                        style={{
                          width: (100 * visibleEdgesCount) / edgesCount + "%",
                        }}
                      />
                    </div>
                  </div>
                </label>
              </li>
            );
          })}
        </ul>
      </Panel>
    );
  };

export default SynchronyPanel;
