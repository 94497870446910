import { useEffect, useState } from "react";

const RedirectCounter = () => {
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return <>Você será redirecionado em {counter} segundos.</>;
};

export default RedirectCounter;
