import {
  EdgeDisplayData,
  NodeDisplayData,
  PartialButFor,
  PlainObject,
} from "sigma/types";
import { Settings } from "sigma/settings";

/**
 * This function draw in the input canvas 2D context a rectangle.
 * It only deals with tracing the path, and does not fill or stroke.
 */
export function drawRoundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number
): void {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

/**
 * Drawing function when a node is hovered
 */
export function drawNodeHover(
  context: CanvasRenderingContext2D,
  data: PlainObject,
  settings: PlainObject
) {
  const size = settings.labelSize * 1.3;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const subLabelSize = size - 2;

  const nodeLabel = data.label;
  const deptLabel = data.value;
  const attributeLabels = data.attributes;

  // Then we draw the label background
  context.beginPath();
  context.fillStyle = "rgb(250,250,250,0.9)";

  context.font = `${weight} ${size}px ${font}`;
  const labelWidth = context.measureText(nodeLabel).width + 90;
  context.font = `${weight} ${subLabelSize}px ${font}`;

  var widestAttributeLabelWidth = 0;
  if (attributeLabels) {
    for (const attr of attributeLabels) {
      let width = context.measureText(attr).width;
      widestAttributeLabelWidth = Math.max(width, widestAttributeLabelWidth);
    }
  }

  context.font = `${weight} ${subLabelSize}px ${font}`;
  const clusterLabelWidth = deptLabel
    ? context.measureText(deptLabel).width
    : 0;

  // Get the widest text
  const textWidth = Math.max(
    labelWidth,
    widestAttributeLabelWidth,
    clusterLabelWidth
  );

  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size);
  const hLabel = Math.round(size / 2 + 4);

  const hAttributeLabel = attributeLabels ? Math.round(subLabelSize) : 0;
  const hDeptLabel = Math.round(subLabelSize / 2 + 9);

  drawRoundRect(
    context,
    x,
    y - hAttributeLabel - 24,
    w,
    hDeptLabel + hLabel + hAttributeLabel * 2 * attributeLabels.length,
    5
  );
  context.closePath();
  context.fill();

  // Draw node label
  context.fillStyle = "#000000";
  context.font = `bold ${size}px ${font} `;
  context.fillText(
    data.key + " | " + nodeLabel,
    data.x + data.size + 3,
    data.y + size / 3
  );

  // Draw department label
  context.fillStyle = data.color;
  context.font = `bold ${subLabelSize}px ${font}`;
  context.fillText(deptLabel, data.x + data.size + 3, data.y - size);

  // Draw all attributes
  if (attributeLabels) {
    context.fillStyle = "#000000";
    context.font = `${weight} ${subLabelSize}px ${font}`;
    for (const [index, subLabel] of attributeLabels.entries()) {
      context.fillText(
        " ● " + subLabel,
        data.x + data.size + 3,
        data.y + size / 2 + (subLabelSize + 4) * (1 + index)
      );
    }
  }
}

/**
 * Drawing function when an edge is hovered
 */
export function drawEdgeHover(
  context: CanvasRenderingContext2D,
  data: PlainObject,
  sourceData: PlainObject,
  targetData: PlainObject,
  settings: PlainObject
) {
  const size = settings.labelSize * 1.3,
    font = settings.labelFont,
    weight = settings.labelWeight;

  const subLabelSize = size - 2;

  // 0,1,2,3 and 4 are synchronous
  var isSynch = data.synchronyType === "Síncrono";

  const syncLabel = data.synchronyType;
  const degreeLabel = data.label;
  const syncDegreeLabel = syncLabel + ": " + degreeLabel;

  const sourceLabel = sourceData.label;
  const targetLabel = targetData.label;

  const relationLabels = [];
  const relationColors = [];

  if (isSynch) {
    relationLabels.push(
      sourceLabel + " ◄► " + targetLabel + " (" + data.answerST + ")"
    );
    relationColors.push(sourceData.color);
  } else {
    relationLabels.push(
      sourceLabel + " ► " + targetLabel + " (" + data.answerST + ")"
    );
    relationColors.push(sourceData.color);
    relationLabels.push(
      targetLabel + "	► " + sourceLabel + " (" + data.answerTS + ")"
    );
    relationColors.push(targetData.color);
  }

  context.beginPath();
  context.fillStyle = "rgb(250,250,250,0.9)";

  context.font = `${weight} ${size}px ${font}`;
  const syncDegreeLabelWidth = context.measureText(syncDegreeLabel).width * 1.2;

  context.font = `${weight} ${subLabelSize}px ${font}`;
  var relationWidth = 5;

  for (const r of relationLabels) {
    relationWidth = Math.max(relationWidth, context.measureText(r).width);
  }

  const textWidth = Math.max(syncDegreeLabelWidth, relationWidth);

  const hSyncDegreeLabel = Math.round(size / 2 + 4);
  const hSubLabel = Math.round(subLabelSize / 2 + 9);

  const h = hSyncDegreeLabel + hSubLabel * relationLabels.length + 35;
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const x = data.hover_x;
  const y = data.hover_y;

  drawRoundRect(context, x - 12, y - h - 10, w, h, 5);
  context.closePath();
  context.fill();

  // Draw edge data
  context.fillStyle = "#000000";
  context.font = `bold ${size}px ${font} `;
  context.fillText(syncDegreeLabel, x + 3, y - h + size);

  for (let i = 0; i < relationLabels.length; ++i) {
    context.fillStyle = relationColors[i];
    context.font = `${weight} ${subLabelSize}px ${font}`;
    context.fillText(
      relationLabels[i],
      x + 3,
      y - h + size + 5 + (subLabelSize + 2) * (1 + i)
    );
  }
}

/**
 * Draw node label on graph
 */
export function drawNodeLabel(
  context: CanvasRenderingContext2D,
  data: PartialButFor<NodeDisplayData, "x" | "y" | "size" | "label" | "color">,
  settings: Settings
): void {
  if (!data.label) return;
  const size = settings.labelSize * 1.2,
    font = settings.labelFont,
    weight = settings.labelWeight;

  context.font = `${weight} ${size}px ${font}`;
  const width = context.measureText(data.key).width;

  context.fillStyle = "#fff";
  context.fillText(data.key, data.x - width / 2, data.y + size / 3);
}

/**
 * Draw edge label on graph
 * This is a hack to show only when an edge is hovered. It was done because to draw it required a CanvasRenderingContext2D object.
 * There must be better ways to improve this.
 */
export function drawEdgeLabel(
  context: CanvasRenderingContext2D,
  edgeData: PartialButFor<EdgeDisplayData, "label" | "size" | "color">,
  sourceData: PartialButFor<NodeDisplayData, "x" | "y" | "size">,
  targetData: PartialButFor<NodeDisplayData, "x" | "y" | "size">,
  settings: Settings
): void {
  // Doesn't draw if it weren't hovered
  if (!edgeData.hovered) return;
  drawEdgeHover(context, edgeData, sourceData, targetData, settings);
}

