import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { getSurveyList } from "../../services/survey.service";
import SurveyRow from "./SurveyRow";
import Modal from "../../components/modal";
import useCompareModal from "./use-modal";
import HelpIcon from "@mui/icons-material/Help";
import * as API from "../../services/survey.service";

import "../../css/survey.css";
import { GrClose } from "react-icons/gr";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Spinner from "../Graph/Spinner";
import SurveyPieChart from "./SurveyPieChart";
import { QuestionInfo, SurveyInfo, TabPanelProps } from "./types";
import Typography from "@mui/material/Typography";
import React from "react";
import SurveyBarChart from "./SurveyBarChart";
import ComparisonTableRow from "./ComparisonTableRow";

const SurveyPage: FC = () => {
  const { accessToken, viewer, Logout } = useAuth();
  const [surveys, setSurveys] = useState<any | null>(null);
  const { isOpen, toggle } = useCompareModal();
  const [selection, setSelection] = useState<number[]>([]);

  const [surveyInfoA, setSurveyInfoA] = useState<SurveyInfo | null>(null);
  const [surveyInfoB, setSurveyInfoB] = useState<SurveyInfo | null>(null);

  const [selectedQuestionA, setSelectedQuestionA] = useState<string>("1");
  const [selectedQuestionB, setSelectedQuestionB] = useState<string>("1");

  const [value, setValue] = React.useState(0);

  function round(value: number, precision: number) {
    var factor = Math.pow(10, precision);
    return Math.round(100 * value * factor) / factor;
  }

  useEffect(() => {
    getSurveyList(accessToken as string).then(({ data }) => setSurveys(data));
  }, []);

  useEffect(() => {
    if (selection.length !== 2) {
      setSurveyInfoA(null);
      setSurveyInfoB(null);
      return;
    }

    API.getSurveyInfo(String(selection[0]), accessToken as string)
      .then((res) => res.data)
      .then((surveyInfo: SurveyInfo) => {
        let questions = surveyInfo.questions.map((q) => {
          if (!q) return null;
          let nConn = q.values.numConnections;
          let precision = 2;
          let result = {
            ...q,
            values: {
              ...q.values,
              null: round(q.values.null / nConn, precision),
              low_synchrony: round(q.values.low_synchrony / nConn, precision),
              mid_synchrony: round(q.values.mid_synchrony / nConn, precision),
              high_synchrony: round(q.values.high_synchrony / nConn, precision),
              high_null_asynchrony: round(
                q.values.high_null_asynchrony / nConn,
                precision
              ),
              mid_null_asynchrony: round(
                q.values.mid_null_asynchrony / nConn,
                precision
              ),
              high_low_asynchrony: round(
                q.values.high_low_asynchrony / nConn,
                precision
              ),
              synchrony: round(q.values.synchrony / nConn, precision),
              asynchrony: round(q.values.asynchrony / nConn, precision),
            },
          };

          return result;
        });
        setSurveyInfoA({ ...surveyInfo, questions: questions });
      });

    API.getSurveyInfo(String(selection[1]), accessToken as string)
      .then((res) => res.data)
      .then((surveyInfo: SurveyInfo) => {
        let questions = surveyInfo.questions.map((q) => {
          if (!q) return null;
          let nConn = q.values.numConnections;
          let precision = 2;
          let result = {
            ...q,
            values: {
              ...q.values,
              null: round(q.values.null / nConn, precision),
              low_synchrony: round(q.values.low_synchrony / nConn, precision),
              mid_synchrony: round(q.values.mid_synchrony / nConn, precision),
              high_synchrony: round(q.values.high_synchrony / nConn, precision),
              high_null_asynchrony: round(
                q.values.high_null_asynchrony / nConn,
                precision
              ),
              mid_null_asynchrony: round(
                q.values.mid_null_asynchrony / nConn,
                precision
              ),
              high_low_asynchrony: round(
                q.values.high_low_asynchrony / nConn,
                precision
              ),
              synchrony: round(q.values.synchrony / nConn, precision),
              asynchrony: round(q.values.asynchrony / nConn, precision),
            },
          };

          return result;
        });
        setSurveyInfoB({ ...surveyInfo, questions: questions });
      });
  }, [selection]);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //Convertendo a data do padrão americano para o brasileiro
  let data_americanaA = surveyInfoA?.end_date.slice(0, 10); //excluindo as horas com slice
  let data_brasileiraA = data_americanaA?.split("-").reverse().join("/");

  let data_americanaB = surveyInfoB?.end_date.slice(0, 10); //excluindo as horas com slice
  let data_brasileiraB = data_americanaB?.split("-").reverse().join("/");
  return (
    <>
      <div className="navbar-fixed">
        <nav>
          <div className="nav-wrapper blue-grey darken-4">
            <div className="brand-logo">
              <img
                src="/images/logo-neuroredes-header.png"
                className="img-responsive"
                alt="Neuroredes"
              />
            </div>

            <ul id="nav-mobile" className="right">
              <li>
                <Link to="/trocarSenha">
                  <span style={{ color: "white" }}>Alterar senha</span>
                </Link>
              </li>
              <li>
                <a href="/" onClick={Logout} style={{ color: "white" }}>
                  Sair
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="body-survey">
        <div className="login-info right-align">
          Autenticado como: {viewer?.nome}
        </div>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="col s1"></div>
          <div className="col s8">
            <h1 style={{ fontFamily: "Public Sans, sans-serif" }}>
              Análises de pesquisas
            </h1>
          </div>
          <div className="col s2 right-align">
            <Tooltip
              title="Selecione 2 (duas) pesquisas para comparar"
              placement="left"
              arrow
            >
              <span>
                {selection.length === 2 ? (
                  <button
                    type="button"
                    className="blue-grey darken-4 btn-large"
                    onClick={toggle}
                    id="btn-compare"
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      Comparar pesquisas
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="blue-grey darken-4 btn-large"
                    onClick={toggle}
                    id="btn-compare"
                    disabled
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      Comparar pesquisas
                    </span>
                  </button>
                )}
              </span>
            </Tooltip>
          </div>
          <div className="col s1"></div>
        </div>

        <div className="row">
          <div className="col s1"></div>
          <div
            className="col s10 scrollResearch"
            style={{ maxHeight: "calc(80vh - 100px)", overflowY: "scroll" }}
          >
            <table className="highlight centered">
              <thead>
                <tr>
                  <th className="label"></th>
                  <th>
                    Selecionar
                    <Tooltip
                      title="Selecione 2 (duas) pesquisas para comparar"
                      placement="top"
                      arrow
                    >
                      <IconButton>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </th>
                  <th>ID</th>
                  <th>Titulo</th>
                  <th>Responsável</th>
                  <th>Período</th>
                  <th>Situação</th>
                  <th>Visualizar</th>
                </tr>
              </thead>
              <tbody>
                {!surveys
                  ? null
                  : surveys.map((survey: any) => (
                      <SurveyRow
                        key={survey.id}
                        survey={survey}
                        selection={selection}
                        setSelection={setSelection}
                      />
                    ))}
              </tbody>
            </table>
          </div>
        </div>

        <Modal isOpen={isOpen} toggle={toggle} id="compare-research-modal">
          <div className="modal-head">
            <h2>Comparar pesquisas</h2>
            <button
              style={{ cursor: "pointer", border: "none" }}
              onClick={toggle}
              title="Fechar"
            >
              <GrClose />
            </button>
          </div>
          <div className="modal-body">
            {surveyInfoB && surveyInfoA ? (
              <>
                <table className="highlight">
                  <thead>
                    <tr style={{ border: "0" }}>
                      <th></th>
                      <th style={{ textAlign: "center" }}>
                        {surveyInfoA.survey}
                      </th>
                      <th style={{ textAlign: "center" }}>
                        {surveyInfoB.survey}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ border: "0" }}>
                      <td></td>
                      <td style={{ textAlign: "center" }}>
                        {data_brasileiraA}
                      </td>

                      <td style={{ textAlign: "center" }}>
                        {data_brasileiraB}
                      </td>
                    </tr>
                    <tr style={{ border: "0" }}>
                      <td></td>
                      <td style={{ textAlign: "center" }}>
                        <FormControl size="small" sx={{ width: "300px" }}>
                          <InputLabel id="surveyB-question-select-label">
                            Perguntas
                          </InputLabel>
                          <Select
                            labelId="surveyB-question-select-label"
                            id="surveyB-question-select"
                            value={selectedQuestionA}
                            label="Perguntas"
                            onChange={(event) => {
                              setSelectedQuestionA(event.target.value);
                            }}
                          >
                            {surveyInfoA.questions
                              .filter((q: QuestionInfo | null) => q !== null)
                              .map((q: QuestionInfo | null) => {
                                if (!q) return <></>;
                                return (
                                  <MenuItem
                                    key={"SurveyBCompareMenu" + q.key}
                                    value={q.key}
                                  >
                                    {q.key} - {q.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <FormControl size="small" sx={{ width: "300px" }}>
                          <InputLabel id="surveyB-question-select-label">
                            Perguntas
                          </InputLabel>
                          <Select
                            labelId="surveyB-question-select-label"
                            id="surveyB-question-select"
                            value={selectedQuestionB}
                            label="Perguntas"
                            onChange={(event) => {
                              setSelectedQuestionB(event.target.value);
                            }}
                          >
                            {surveyInfoB.questions
                              .filter((q: QuestionInfo | null) => q !== null)
                              .map((q: QuestionInfo | null) => {
                                if (!q) return <></>;
                                return (
                                  <MenuItem
                                    key={"SurveyBCompareMenu" + q.key}
                                    value={q.key}
                                  >
                                    {q.key} - {q.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Tabela" {...a11yProps(0)} />
                    <Tab label="Gráfico Pizza" {...a11yProps(1)} />
                    <Tab label="Gráfico Barra" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="row">
                    <div className="col s12">
                      <>
                        <div className="row">
                          <div className="col s12">
                            <table className="highlight">
                              <thead>
                                <tr style={{ border: "0" }}>
                                  <th></th>
                                  <th style={{ textAlign: "center" }}>
                                    {surveyInfoA.survey}
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    {surveyInfoB.survey}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ComparisonTableRow
                                  isTitle={true}
                                  isPercent={true}
                                  text="Sincronia"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.synchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.synchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Alto"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.high_synchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.high_synchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Média"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.mid_synchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.mid_synchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Baixa"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.low_synchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.low_synchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={true}
                                  isPercent={true}
                                  text="Assincronia"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.asynchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.asynchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Alto / Baixo"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.high_low_asynchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.high_low_asynchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Médio / Nulo"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.mid_null_asynchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.mid_null_asynchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={false}
                                  isPercent={true}
                                  text="Alto / Nulo"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.high_null_asynchrony
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.high_null_asynchrony
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={true}
                                  isPercent={true}
                                  text="Nulos"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.null
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.null
                                  }
                                />
                                <ComparisonTableRow
                                  isTitle={true}
                                  isPercent={false}
                                  text="Participantes"
                                  valueA={surveyInfoA.participants}
                                  valueB={surveyInfoB.participants}
                                />
                                <ComparisonTableRow
                                  isTitle={true}
                                  isPercent={false}
                                  text="Máximo de ligações"
                                  valueA={
                                    surveyInfoA.questions[
                                      (+selectedQuestionA - 1) as any
                                    ]?.values.numConnections
                                  }
                                  valueB={
                                    surveyInfoB.questions[
                                      (+selectedQuestionB - 1) as any
                                    ]?.values.numConnections
                                  }
                                />
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <table className="highlight" style={{ tableLayout: "fixed" }}>
                    <thead>
                      <tr style={{ border: "0" }}>
                        <th style={{ textAlign: "center" }}>
                          {surveyInfoA.survey}
                        </th>
                        <th style={{ textAlign: "center" }}>
                          {surveyInfoB.survey}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <SurveyPieChart
                            surveyInfo={surveyInfoA}
                            selectedQuestion={selectedQuestionA}
                          />
                        </td>
                        <td>
                          <SurveyPieChart
                            surveyInfo={surveyInfoB}
                            selectedQuestion={selectedQuestionB}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <SurveyBarChart
                    surveyInfoA={surveyInfoA}
                    surveyInfoB={surveyInfoB}
                    selectedQuestionA={selectedQuestionA}
                    selectedQuestionB={selectedQuestionB}
                  />
                </TabPanel>
              </>
            ) : (
              <>
                <div className="row">
                  Carregando...
                  <div
                    className="col s12"
                    style={{
                      paddingLeft: "5em",
                      height: "600px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SurveyPage;
