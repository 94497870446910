import { FC, useEffect, useMemo, useState } from 'react';
import { useSigma } from 'react-sigma-v2';
import { MdCategory } from 'react-icons/md';
import { omit, sortBy, values } from 'lodash';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';

import { FiltersState, Attribute, GraphStyle } from './types';
import Panel from './Panel';
import { getRandomColor } from './utils';

/**
 * Panel used to filter the attributes
 * @param attribute
 * @param graphStyle
 * @param colorize
 * @param filters
 * @returns the graphical element
 */
const AttributePanel: FC<{
  attribute: Attribute;
  graphStyle: GraphStyle;
  colorize: string;
  filters: FiltersState;
  //children: React.ReactNode;
  setFiltersState: (filtersState: FiltersState) => void;
}> = ({ attribute, graphStyle, colorize, filters, setFiltersState }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const tags = attribute.values;

  /**
   * Calculate the number of nodes for each tag
   */
  const nodesPerTag = useMemo(() => {
    const index: Record<string, number> = {};
    graph.forEachNode((_, { attributes }) => {
      for (const attr of attributes) {
        for (const tag of tags) {
          if (tag.key === attr) {
            index[attr] = (index[attr] || 0) + 1;
          }
        }
      }
    });
    return index;
  }, []);

  /**
   * Calculate the highest number of nodes among all the tags
   */
  const maxNodesPerTag = useMemo(() => Math.max(...values(nodesPerTag)), []);

  /**
   * Calculate the number of visible tags
   */
  const visibleTagsCount = useMemo(() => {
    let total = 0;
    for (const tag of tags) {
      if (filters.attribute_tags[tag.key]) {
        total += 1;
      }
    }
    return total;
  }, [filters]);

  /**
   * Toggle the tag in the filter
   * @param tag_key key to be toggle
   */
  function toggleTag(tag_key: string) {
    setFiltersState({
      ...filters,
      attribute_tags: filters.attribute_tags[tag_key]
        ? omit(filters.attribute_tags, tag_key)
        : { ...filters.attribute_tags, [tag_key]: true },
    });
  }

  /**
   * Set the tag state in the filter
   * @param tags
   */
  function setTags(tags: Record<string, boolean>) {
    setFiltersState({
      ...filters,
      attribute_tags: tags,
    });
  }

  /**
   * Set the number of visible nodes per tag
   */
  const [visibleNodesPerTag, setVisibleNodesPerTag] =
    useState<Record<string, number>>(nodesPerTag);
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index: Record<string, number> = {};
      graph.forEachNode((_, { attributes, hidden }) => {
        for (const attr of attributes) {
          !hidden && (index[attr] = (index[attr] || 0) + 1);
        }
      });
      setVisibleNodesPerTag(index);
    });
  }, [filters]);

  /**
   * Sort tags alphabetically
   */
  const sortedTags = useMemo(
    () =>
      sortBy(tags, (tag) =>
        tag.key === 'unknown' ? Infinity : -nodesPerTag[tag.key]
      ),
    [tags, nodesPerTag]
  );

  //console.log(attribute.key);

  /**
   * Draw the element
   */
  return (
    // Draw panel header
    <Panel
      title={
        <>
          <MdCategory className="text-muted" /> {attribute.key}
        </>
      }>
      {/* 'Select all' or 'Unselect all' button */}
      <p className="buttons">
        <button
          className="btn-small"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'grey',
            border: '1px solid #eee',
          }}
          onClick={() => {
            let m = filters.attribute_tags;
            tags.forEach((tag) => {
              m[tag.key] = true;
            });
            setTags(m);
          }}>
          <AiOutlineCheckCircle /> Todos
        </button>{' '}
        <button
          className="btn-small"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'grey',
            border: '1px solid #eee',
          }}
          onClick={() => {
            let m = filters.attribute_tags;
            tags.forEach((tag) => {
              m[tag.key] = false;
            });
            setTags(m);
          }}>
          <AiOutlineCloseCircle /> Nenhum
        </button>
      </p>
      {/* Draw tag list */}
      <ul>
        {tags.map((tag, i) => {
          // FIXME: node count is not correct.
          const nodesCount = nodesPerTag[tag.key] ? nodesPerTag[tag.key] : 0;
          const visibleNodesCount = visibleNodesPerTag[tag.key] || 0;

          if (!graphStyle.depts_style[i + 1]) {
            graphStyle.depts_style[i + 1] = {
              key: ((i as number) + 1).toString(),
              color: getRandomColor(),
            };
          }

          return (
            <li
              className="caption-row"
              key={tag.key}
              title={`${nodesCount} usuário${nodesCount > 1 ? 's' : ''}${
                visibleNodesCount !== nodesCount
                  ? ` ( exibindo ${visibleNodesCount} )`
                  : ''
              }`}>
              <input
                type="checkbox"
                checked={filters.attribute_tags[tag.key] || false}
                onChange={() => toggleTag(tag.key)}
                id={`tag-${tag.key}`}
              />
              <label htmlFor={`tag-${tag.key}`}>
                {visibleNodesCount ? (
                  <span
                    className="circle"
                    style={{
                      background:
                        colorize === attribute.key
                          ? graphStyle.depts_style[i + 1].color
                          : 'grey',
                      borderColor:
                        colorize === attribute.key
                          ? graphStyle.depts_style[i + 1].color
                          : 'grey',
                    }}
                  />
                ) : (
                  <span
                    className="circle"
                    style={{
                      background:
                        colorize === attribute.key
                          ? graphStyle.depts_style[i + 1].color
                          : 'white',
                      borderColor:
                        colorize === attribute.key
                          ? graphStyle.depts_style[i + 1].color
                          : '#eee',
                    }}
                  />
                )}{' '}
                <div className="node-label">
                  <span>{tag.key}</span>
                  <div
                    className="bar"
                    style={{
                      width: (100 * nodesCount) / maxNodesPerTag + '%',
                    }}>
                    <div
                      className="inside-bar"
                      style={{
                        width: (100 * visibleNodesCount) / nodesCount + '%',
                      }}
                    />
                  </div>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    </Panel>
  );
};

export default AttributePanel;
