import { Dispatch, FC, SetStateAction, useState } from "react";
import { useAuth } from "../../contexts/auth";

interface ChildProps {
  setMode: Dispatch<SetStateAction<"login" | "forget" | "firstAccess">>;
}

const LoginForm: FC<ChildProps> = ({ setMode }) => {
  const { PasswordLogin } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [keepConnected, setKeepConnected] = useState(true);
  const [errorMessageLogin, setErrorMessageLogin] = useState(false);
  const [errorMessageEmail, setErrorMessageEmail] = useState(false);
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);


  const handleLogin = async () => {
    var reg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

    if (reg.test(email)) {
      if (password !== "") {
        let logged = await PasswordLogin(email, password, keepConnected);
        // Precisa do if por causa do unmount.
        if (!logged) {
          setErrorMessageLogin(!logged);
        }
      }
      else {
        setErrorMessagePassword(true);
      }
    }
    else {
      setErrorMessageEmail(true);
    }
  };


  const handleChange = () => {
    setKeepConnected(!keepConnected);
  };

  return (
    <>
      <h1
        style={{
          fontSize: "2.2em",
          fontFamily: "sans-serif",
          color: "#AAA",
          marginBottom: "2em",
        }}
      >
        SISTEMA DE ANÁLISE
      </h1>

      {/* Login Form */}
      <div style={{ display: "flex", flexFlow: "column", padding: "0 3em" }}>
        {/* Error */}
        {errorMessageLogin && (
          <div id="login-msg-holder">
            <p id="login-error-msg">E-mail ou senha incorretos</p>
          </div>
        )}
        {errorMessageEmail && (
          <div id="login-msg-holder">
            <p id="login-error-msg">Informe um email válido!</p>
          </div>
        )}
        {errorMessagePassword && (
          <div id="login-msg-holder">
            <p id="login-error-msg">Informe sua senha!</p>
          </div>
        )}


        <input
          type="email"
          id="login"
          name="login"
          onChange={(event) => {
            setErrorMessageLogin(false);
            setErrorMessageEmail(false);
            setEmail(event.target.value);
          }}
          value={email}
          placeholder="E-mail"
          required
        />
        <input
          type="password"
          id="password"
          name="password"
          className="fadeIn first"
          onChange={(event) => {
            setErrorMessageLogin(false);
            setErrorMessagePassword(false);
            setPassword(event.target.value);
          }}
          value={password}
          placeholder="Senha"
          required
        />
        <div className="fourth" style={{ margin: "5px"}}>
          <label htmlFor="keepConnected">
            <input
              type="checkbox"
              id="keepConnected"
              name="keepConnected"
              checked={!keepConnected}
              onChange={handleChange}
            />
            <span>Manter conectado</span>
          </label>
        </div>

        <input
          type="submit"
          className="fifth"
          value="Iniciar sessão"
          onClick={handleLogin}
        />
      </div>

      {/* Remind password */}
      <div
        id="formFooter"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <p
          className="underlineHover"
          style={{ cursor: "pointer" }}
          onClick={() => setMode("forget")}
        >
          Esqueci a senha
        </p>
        <p
          className="underlineHover"
          style={{ cursor: "pointer" }}
          onClick={() => setMode("firstAccess")}
        >
          Primeiro acesso
        </p>
      </div>
    </>
  );
};

export default LoginForm;
