import React, { ReactNode } from "react";

interface ReportModalType {
  children?: ReactNode;
  isOpenReportModal: boolean;
  toggleReportModal: () => void;
}

export default function ReportModal(props: ReportModalType) {
  return (
    <>
      {props.isOpenReportModal && (
        <div className="modal-report-overlay" onClick={props.toggleReportModal}>
          <div onClick={(e) => e.stopPropagation()} className="modal-report-box hoverable">
            {props.children}
          </div>
        </div>
      )}
    </>
  );
}
