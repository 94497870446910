import { useState } from "react";

export default function useLeftPanel() {
  const [isOpenLeftPanel, setisOpenLeftPanel] = useState(false);

  const toggleLeftPanel = () => {
    setisOpenLeftPanel(!isOpenLeftPanel);
  };

  return {
    isOpenLeftPanel,
    toggleLeftPanel,
  };
}
