import { useState } from "react";

export default function useReportModal() {
  const [isReportModalOpen, setReportModalisOpen] = useState(false);

  const reportModalToggle = () => {
    setReportModalisOpen(!isReportModalOpen);
  };

  return {
    isReportModalOpen: isReportModalOpen,
    reportModalToggle,
  };
}
