import { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Import stylesheets
import "./css/login.css";
import "./css/graph.css";
import "./css/spinner.css";
import "./css/modal.css"

const rootNode = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  rootNode
);
