import { FC } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Survey from "../pages/Survey";
import ChangePasswordPage from "../pages/Password";
import Graph from "../pages/Graph/Graph";
import AccessPage from "../pages/Login/AccessPage";

const PrivateRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/analises" element={<Survey />} />
        <Route path="/pesquisa/:surveyId" element={<Graph />} />
        <Route path="/trocarSenha" element={<ChangePasswordPage />} />
        <Route path="/access/:token" element={<AccessPage />} />
        <Route path="*" element={<Navigate to="/analises" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PrivateRoutes;
