import { FC, useEffect, useMemo, useState } from 'react';
import { useSigma } from 'react-sigma-v2';
import { sortBy, values, keyBy, mapValues, omit } from 'lodash';
import { MdGroupWork } from 'react-icons/md';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { Department, FiltersState, GraphStyle } from './types';
import Panel from './Panel';
/* import { BsPencil } from "react-icons/bs"; */

/**
 * Panel used to filter the attributes
 * @param depts
 * @param colorize
 * @param graphStyle
 * @param filters
 * @returns the graphical element
 */
const DeptsPanel: FC<{
  depts: Department[];
  colorize: string;
  graphStyle: GraphStyle;
  filters: FiltersState;
  setFiltersState: (filtersState: FiltersState) => void;
}> = ({ depts, colorize, graphStyle, filters, setFiltersState }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  /**
   * Calculate the number of nodes for each department
   */
  const nodesPerDept = useMemo(() => {
    const index: Record<string, number> = {};
    graph.forEachNode((_, { dept }) => (index[dept] = (index[dept] || 0) + 1));
    return index;
  }, []);

  /**
   * Calculate the highest number of nodes among all the departments
   */
  const maxNodesPerDept = useMemo(
    () => Math.max(...values(nodesPerDept)),
    [nodesPerDept]
  );

  /**
   * Calculate the number of departments
   */
  const visibleDeptsCount = useMemo(
    () => Object.keys(filters.depts).length,
    [filters]
  );

  //Essas 2 funções abaixo são para uma possível futura implementação
  /**
   * Show the icon configuration color for department
   * @param dept_key key to be toggle
   */
  /* function showIconConfig(dept_key: string) {
    const id = "icon-" + dept_key;
    const icon = document.getElementById(id);
    if (icon != null) {
      icon.style.visibility = '';
    }
  } */

  /**
   * Hidden the icon configuration color for department
   * @param dept_key key to be toggle
   */
  /* function hiddenIconConfig(dept_key: string) {
    const id = "icon-" + dept_key;
    const icon = document.getElementById(id);
    if (icon != null) {
      icon.style.visibility = 'hidden';
    }
  } */

  /**
   * Toggle the department in the filter
   * @param dept_key key to be toggle
   */
  function toggleDept(dept_key: string) {
    setFiltersState({
      ...filters,
      depts: filters.depts[dept_key]
        ? omit(filters.depts, dept_key)
        : { ...filters.depts, [dept_key]: true },
    });
  }

  /**
   * Set the depts state in the filter
   * @param tags
   */
  function setDepts(depts: Record<string, boolean>) {
    setFiltersState({
      ...filters,
      depts: depts,
    });
  }

  /**
   * Set the number of visible nodes per department
   */
  const [visibleNodesPerDept, setVisibleNodesPerDept] =
    useState<Record<string, number>>(nodesPerDept);
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index: Record<string, number> = {};
      graph.forEachNode(
        (_, { dept, hidden }) =>
          !hidden && (index[dept] = (index[dept] || 0) + 1)
      );
      setVisibleNodesPerDept(index);
    });
  }, [filters]);

  /**
   * Sort department alphabetically
   */
  const sortedDepts = useMemo(
    () => sortBy(depts, (dept) => -nodesPerDept[dept.key]),
    [depts, nodesPerDept]
  );

  /**
   * Draw the element
   */
  return (
    // Draw panel header
    <Panel
      title={
        <>
          <MdGroupWork className="text-muted" /> Atributo 1
        </>
      }>
      {/* 'Select all' or 'Unselect all' button */}
      <p className="buttons">
        <button
          className="btn-small"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'grey',
            border: '1px solid #eee',
          }}
          onClick={() => setDepts(mapValues(keyBy(depts, 'key'), () => true))}>
          <AiOutlineCheckCircle /> Todos
        </button>{' '}
        <button
          className="btn-small"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'grey',
            border: '1px solid #eee',
          }}
          onClick={() => setDepts({})}>
          <AiOutlineCloseCircle /> Nenhum
        </button>
      </p>
      {/* Draw department list */}
      <ul>
        {sortedDepts.map((dept) => {
          // FIXME: node count is not correct.
          const nodesCount = nodesPerDept[dept.key];
          const visibleNodesCount = visibleNodesPerDept[dept.key] || 0;
          return (
            <li
              className="caption-row"
              key={dept.key}
              title={`${nodesCount} usuário${nodesCount > 1 ? 's' : ''}${
                visibleNodesCount !== nodesCount
                  ? ` ( exibindo ${visibleNodesCount} )`
                  : ''
              }`}
              style={{ display: 'flex', flexDirection: 'row' }}
              /* onMouseOver={() => { showIconConfig(dept.key); }}
            onMouseOut={() => { hiddenIconConfig(dept.key); }} */
            >
              <input
                type="checkbox"
                onChange={() => {
                  toggleDept(dept.key);
                }}
                checked={filters.depts[dept.key] || false}
                id={`relations-${dept.key}`}
              />
              <label
                htmlFor={`dept-${dept.key}`}
                onClick={() => {
                  toggleDept(dept.key);
                }}
                style={{ flexGrow: 1 }}>
                {visibleNodesCount ? (
                  <span
                    className="circle"
                    style={{
                      background:
                        colorize === 'depts'
                          ? graphStyle.depts_style[dept.key as any].color
                          : 'grey',
                      borderColor:
                        colorize === 'depts'
                          ? graphStyle.depts_style[dept.key as any].color
                          : 'grey',
                    }}></span>
                ) : (
                  <span
                    className="circle"
                    style={{
                      background: 'white',
                      borderColor: '#eee',
                    }}></span>
                )}{' '}
                <div className="node-label" style={{ width: '100%' }}>
                  <span>{dept.value}</span>
                  <div
                    className="bar"
                    style={{
                      width: (100 * nodesCount) / maxNodesPerDept + '%',
                    }}>
                    <div
                      className="inside-bar"
                      style={{
                        width: (100 * visibleNodesCount) / nodesCount + '%',
                      }}
                    />
                  </div>
                </div>
              </label>
              {/* <button
                type="button"
                id={`icon-${dept.key}`}
                onClick={() => { showModal(dept.key); }}
                style={{ border: "none", backgroundColor: "rgb(0,0,0,0)", visibility: "hidden" }}
                title="Mudar cor da legenda"
              >
                <BsPencil />
              </button>
              <canvas id={`icon-${dept.key}`} width="150" height="150" style={{ display: "none" }}></canvas> */}
            </li>
          );
        })}
      </ul>
    </Panel>
  );
};

export default DeptsPanel;
