import axios from "axios";
import { API_URL } from './service';

export const getSurveyGraph = (surveyId: string, token: string) => {
  return axios.get(API_URL + `survey/${surveyId}/nodes`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const getSurveyQuestion = (surveyId: string, token: string, question: string) => {
  return axios.get(API_URL + `survey/${surveyId}/edges/${question}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const getSurveyList = (token: string) => {
  return axios.get(API_URL + "survey", {
    headers: { Authorization: `Bearer ${token}` }
  });
};

export const getExcelReport = (surveyId: string, token: string) => {
  return axios.get(API_URL + `survey/${surveyId}/report/excel`, {
    headers: { Authorization: `Bearer ${token}` },
    responseType: 'blob'
  });

  // return axios.get(API_URL + `survey/${surveyId}/report/aws`, {
  //   headers: { Authorization: `Bearer ${token}` },
  //   responseType: 'blob'
  // });
};

export const getLegend = (token: string) => {
  return fetch(API_URL + 'survey/legend', {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getRespondentStats = (surveyId: string, question: string, respondent: string, token: string) => {
  return axios.get(API_URL + `survey/${surveyId}/edges/${question}/respondent/${respondent}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSurveyInfo = (surveyId: string, token: string) => {
  return axios.get(API_URL + `survey/${surveyId}/stats`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};