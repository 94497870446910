import { FC } from "react";

/**
 * Draw the loading spinner, used when the graph is being downloaded
 * @returns the graphical element
 */
const Spinner: FC = () => {
  return (
    // See spinner.css
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
