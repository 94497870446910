import { FC, useEffect, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { Dataset, FiltersState, Statistics } from "./types";
import { useAuth } from "../../contexts/auth";
import * as API from "../../services/survey.service";
import { SurveyInfo } from "../Survey/types";
import { round } from "./utils";
import Spinner from "./Spinner";

/**
 * Panel that show information about the graph and selected node
 * @param param0
 * @returns the graphical element
 */
const StatisticsPanel: FC<{
  currentQuestion: string | null;
  dataset: Dataset;
  filters: FiltersState;
  selectedNode: string | null;
  surveyId: number;
}> = ({ currentQuestion, dataset, filters, selectedNode, surveyId }) => {
  const { accessToken } = useAuth();
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const [surveyInfo, setSurveyInfo] = useState<SurveyInfo | null>(null);
  const [nodeStats, setNodeStats] = useState<Statistics | null>(null);

  /**
   * Format the number in percentage
   * @param val the float number
   * @returns a formatted string
   */
  function prettyPercentage(val: number): string {
    return (val * 100).toFixed(1) + "%";
  }

  //  Uncomment this if the survey statistics is to be shown
  // useEffect(()=>{
  //   API.getSurveyInfo(String(surveyId), accessToken as string)
  //     .then((res) => res.data)
  //     .then((surveyInfo: SurveyInfo) => {
  //       let questions = surveyInfo.questions.map((q) => {
  //         if (!q) return null;
  //         let nConn = q.values.numConnections;
  //         let precision = 2;
  //         let result = {
  //           ...q,
  //           values: {
  //             ...q.values,
  //             null: round(q.values.null / nConn, precision),
  //             low_synchrony: round(q.values.low_synchrony / nConn, precision),
  //             mid_synchrony: round(q.values.mid_synchrony / nConn, precision),
  //             high_synchrony: round(q.values.high_synchrony / nConn, precision),
  //             high_null_asynchrony: round(
  //               q.values.high_null_asynchrony / nConn,
  //               precision
  //             ),
  //             mid_null_asynchrony: round(
  //               q.values.mid_null_asynchrony / nConn,
  //               precision
  //             ),
  //             high_low_asynchrony: round(
  //               q.values.high_low_asynchrony / nConn,
  //               precision
  //             ),
  //             synchrony: round(q.values.synchrony / nConn, precision),
  //             asynchrony: round(q.values.asynchrony / nConn, precision),
  //           },
  //         };

  //         return result;
  //       });
  //       setSurveyInfo({ ...surveyInfo, questions: questions });
  //     });
  // },[])


  /**
   * Calculate the number of visible nodes, visible edges and maximum number of edges
   */
  const [visibleItems, setVisibleItems] = useState<{
    nodes: number;
    edges: number;
    maxEdges: number;
  }>({ nodes: 0, edges: 0, maxEdges: 0 });
  useEffect(() => {
    // To ensure the graphology instance has up to data "hidden" values for
    // nodes, we wait for next frame before reindexing. This won't matter in the
    // UX, because of the visible nodes bar width transition.
    requestAnimationFrame(() => {
      const index = { nodes: 0, edges: 0, maxEdges: 0 };

      graph.forEachNode((_, { hidden }) => !hidden && index.nodes++);
      graph.forEachEdge((_, { hidden }, _2, _3, source, target) => {
        index.maxEdges++;
        return !hidden && !source.hidden && !target.hidden && index.edges++;
      });
      setVisibleItems(index);
    });
  }, [filters, currentQuestion]);

  /**
   * Get selected node statistics from backend
   */
  useEffect(() => {
    // Only if a node was selected
    if (!selectedNode) {
      setNodeStats(null);
      return;
    }

    setNodeStats(null);
    if (!surveyId || !currentQuestion) {
      return;
    }
    API.getRespondentStats(
      surveyId.toString(),
      String(Number(currentQuestion) + 1),
      String(graph.getNodeAttribute(selectedNode, "id")),
      accessToken as string
    )
      .then((res) => res.data)
      .then((stats: Statistics) => {
        setNodeStats(stats);
      });
  }, [selectedNode, currentQuestion]);


  function showStatistics(){
    if(selectedNode){
      if(nodeStats){
        return <>
        <div className="divider" style={{ margin: "15px 0px" }}></div>

        <div className="container">
          <div className="row">
            <div className="col s12 label">
              <label htmlFor="nomeUsuario">
                {
                  dataset.depts[
                    Number(
                      graph.getNodeAttribute(
                        String(selectedNode),
                        "dept"
                      )
                    ) - 1
                  ].value
                }
              </label>
            </div>
            <div className="col s12" id="nomeUsuario">
              <h2 style={{ fontWeight: "bolder" }}>
                {selectedNode}
                {" | "}
                {graph.getNodeAttribute(selectedNode, "label")}
              </h2>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "0" }}>
            <div
              className="col s6"
              style={{ borderRight: "1px solid #eee" }}
            >
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Sincronia:{" "}
                    </td>
                    <td style={{ width: "40%" }}>
                      {nodeStats?.synchrony}%
                    </td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Alto:</td>
                    <td>{nodeStats?.high_synchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Médio:</td>
                    <td>{nodeStats?.mid_synchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Baixo:</td>
                    <td>{nodeStats?.low_synchrony}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Assincronia:
                    </td>
                    <td>{nodeStats?.asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Alto / Nulo:
                    </td>
                    <td>{nodeStats?.high_null_asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Médio / Nulo:
                    </td>
                    <td>{nodeStats?.null_mid_asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Alto / Baixo:
                    </td>
                    <td>{nodeStats?.high_low_asynchrony}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="divider" style={{ margin: "5px 0px" }}></div>
          <div className="row" style={{ marginBottom: "0" }}>
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ fontWeight: "bolder" }}>Nulo:</td>
                    <td>{nodeStats?.null}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="divider" style={{ margin: "5px 0px" }}></div>
          <div
            className="row"
            style={{ marginBottom: "0", paddingBottom: "0" }}
          >
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr style={{ borderBottom: "0" }}>
                    <td
                      style={{ paddingBottom: "0", paddingRight: "0" }}
                    >
                      <span style={{ fontWeight: "bolder" }}>
                        Grau de entrada:
                      </span>
                      <span style={{ marginLeft: "1em" }}>
                        {nodeStats?.in_degree}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr style={{ borderBottom: "0" }}>
                    <td
                      style={{ paddingBottom: "0", paddingRight: "0" }}
                    >
                      <span style={{ fontWeight: "bolder" }}>
                        Grau de saída:
                      </span>
                      <span style={{ marginLeft: "1em" }}>
                        {nodeStats?.out_degree}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
      }else{
        return <>
        <div className="row">
         
          <div
            className="col s12"
            style={{
              paddingLeft: "5em",
              height: "200px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner />
            <br/>
            Carregando...
          </div>
        </div>
      </>
      }
    }
    /* else{
      if(surveyInfo){
        return <>
        <div className="divider" style={{ margin: "15px 0px" }}></div>
        <div className="container">
          <div className="row" style={{ marginBottom: "0" }}>
            <div
              className="col s6"
              style={{ borderRight: "1px solid #eee" }}
            >
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Sincronia:{" "}
                    </td>
                    <td style={{ width: "40%" }}>
                      {surveyInfo.questions[currentQuestion as any]?.values.synchrony}%
                    </td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Alto:</td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.high_synchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Médio:</td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.mid_synchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td>Baixo:</td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.low_synchrony}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bolder" }}>
                      Assincronia:
                    </td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Alto / Nulo:
                    </td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.high_null_asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Médio / Nulo:
                    </td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.mid_null_asynchrony}%</td>
                  </tr>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      Alto / Baixo:
                    </td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.high_low_asynchrony}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="divider" style={{ margin: "5px 0px" }}></div>
          <div className="row" style={{ marginBottom: "0" }}>
            <div className="col s6">
              <table className="highlight">
                <thead></thead>
                <tbody>
                  <tr style={{ borderBottom: "0" }}>
                    <td style={{ fontWeight: "bolder" }}>Nulo:</td>
                    <td>{surveyInfo.questions[currentQuestion as any]?.values.null}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="divider" style={{ margin: "5px 0px" }}></div>
          <div
            className="row"
            style={{ marginBottom: "0", paddingBottom: "0" }}
          >
          </div>
        </div>
      </>
      }else{
        return <>
        <div className="row">
         
         <div
           className="col s12"
           style={{
             paddingLeft: "5em",
             height: "200px",
             display: "flex",
             justifyContent: "center",
           }}
         >
           <Spinner />
           <br/>
           Carregando...
         </div>
       </div>
      </>
      }

    } */
  }


  /**
   * Draw graphical element
   */
  return (
    <div className="graph-title">
      <div className="container">
        <div className="row">
          <div className="col s12">
            <div>
              <h4 style={{ textAlign: "center" }}>{dataset.company}</h4>
              <div className="divider" style={{ margin: "15px 0px" }}></div>
              <div style={{ fontWeight: "bolder" }}>
                Pergunta {dataset.questions[currentQuestion as any].key}
              </div>
              <div>{dataset.questions[currentQuestion as any].label}</div>
            </div>
            {/*  <div className="divider" style={{ margin: "15px 0px" }}></div> */}

            {/* Draw general information about the graph and selected question */}
            <table className="highlight" id="generalStatistic">
              <thead>
                <tr style={{ borderBottom: "0" }}>
                  <th></th>
                  <th style={{ textAlign: "center" }}>Total</th>
                  <th style={{ textAlign: "center" }}>Exibindo</th>
                  <th style={{ textAlign: "center" }}>Exibindo (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: "0" }}>
                  <td style={{ fontWeight: "bolder" }}>Usuários: </td>
                  <td style={{ textAlign: "center" }}>{graph.order}</td>
                  <td style={{ textAlign: "center" }}>{visibleItems.nodes}</td>
                  <td style={{ textAlign: "center" }}>
                    {prettyPercentage(visibleItems.nodes / graph.order)}
                  </td>
                </tr>
                <tr style={{ borderBottom: "0" }}>
                  <td style={{ fontWeight: "bolder" }}>Conexões: </td>
                  <td style={{ textAlign: "center" }}>
                    {visibleItems.maxEdges}
                  </td>
                  <td style={{ textAlign: "center" }}>{visibleItems.edges}</td>
                  <td style={{ textAlign: "center" }}>
                    {prettyPercentage(visibleItems.edges / graph.size)}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* If a node is selected, show its statistics */}
            {showStatistics()}
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatisticsPanel;
