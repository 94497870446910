import axios from 'axios';
import CryptoJS from "crypto-js";
import { API_URL } from './service';

export interface Viewer {
  id: number;
  email: string;
  nome: string;
  empresa_id: number;
}

const CryptoJSAesJson = {
  stringify: function (cipherParams: CryptoJS.lib.CipherParams) {
    const j = {
      ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64),
      iv: cipherParams.iv.toString(),
      s: cipherParams.salt.toString(),
    };
    return JSON.stringify(j);
  },
  parse: function (jsonStr: string) {
    const j = JSON.parse(jsonStr);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

//export const register = (username: string, email: string, password: string) => {
//  return axios.post(API_URL + "auth/signup", {
//    username,
//    email,
//    password,
//  });
//};

const getKey = async () => {
  const { data } = await axios.get(API_URL + "auth/key");
  return data;
};

export const login = async (email: string, password: string) => {
  const passphrase = await getKey();

  // Encript password.
  const ciphertext = CryptoJS.AES.encrypt(password, passphrase, {
    format: CryptoJSAesJson,
  }).toString();

  const { data } = await axios
    .post(API_URL + "auth/login", { email: email, password: ciphertext });
  return data;
  /*
  return axios
    .post(API_URL + "auth/login", { email: email, password: ciphertext })
    .then(res => {
      if (res.status === 200) { return res; }
      return null;
    }).catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        // Access to config, request, and response
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          //console.log(err.response.data);
          //console.log(err.response.status);
          //console.log(err.response.headers);
        } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log('Error', err.message);
        }
        //console.log(err.config);
      } else {
        // Just a stock error
      }
      return null;
    });
    */
};

export const requestPassword = async (email: string) => {
  const { data } = await axios.post(API_URL + "auth/viewer/password", {
    email: email
  });
  return data;
};


export const getCurrentViewer = async (token: string) => {
  const { data } = await axios.get(API_URL + "auth/viewer", {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return data;
};

export const setPassword = async (viewer: Viewer, password: string, token: string) => {
  const passphrase = await getKey();

  // Encript password.
  const ciphertext = CryptoJS.AES.encrypt(password, passphrase, {
    format: CryptoJSAesJson,
  }).toString();

  const newViewer = {
    id: viewer.id,
    email: viewer.email,
    nome: viewer.nome,
    empresa_id: viewer.empresa_id,
    password: ciphertext
  };

  const response = await axios.put(API_URL + "auth/viewer/" + viewer.id,
    { viewer: newViewer }, { headers: { 'Authorization': `Bearer ${token}` } });

  return response.status === 200;
};
