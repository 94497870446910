import { FC, useState } from "react";

import LoginForm from "./LoginForm";
import NoPasswordForm from "./NoPasswordForm";

import "../../css/login.css";

const LoginPage: FC = () => {
  const [mode, setMode] = useState<"login" | "forget" | "firstAccess">("login");

  return (
    <div style={{ backgroundColor: "#56baed", height: "100%" }}>
      <div className="wrapper fadeInDown">
        <div id="formContent">
          {/* Logo */}
          <div>
            <img src="/images/neuroredes.png" id="logo" alt="Neuroredes logo" style={{maxWidth:"300px"}}/>
          </div>
          {mode === "login" && <LoginForm setMode={setMode} />}
          {(mode === "forget" || mode === "firstAccess") && (
            <NoPasswordForm setMode={setMode} />
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
