import { Dispatch, FC, SetStateAction, useState } from "react";

import * as API from "../../services/auth.service";

interface ChildProps {
  setMode: Dispatch<SetStateAction<"login" | "forget" | "firstAccess">>;
}

const NoPasswordForm: FC<ChildProps> = ({ setMode }) => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState(false);

  function sendEmail() {
    API.requestPassword(email);
    setMessage(true);
  }

  return (
    <>
      <h1
        style={{ fontSize: "2.2em", fontFamily: "sans-serif", color: "#AAA" }}
      >
        SISTEMA DE ANÁLISE
      </h1>

      {/* Remind Password */}
      <div style={{ display: "flex", flexFlow: "column", padding: "0 3em" }}>
        {/* Message */}
        {message && (
          <div id="login-msg-holder">
            <p id="login-success-msg">E-mail enviado</p>
          </div>
        )}

        <input
          type="email"
          id="login"
          name="login"
          className={`second`}
          onChange={(event) => {
            setMessage(false);
            setEmail(event.target.value);
          }}
          value={email}
          placeholder="E-mail"
          required
        />

        <input
          type="submit"
          className="fifth"
          value="Enviar e-mail"
          onClick={sendEmail}
        />
      </div>
      <div
        id="formFooter"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <p
          className="underlineHover"
          style={{ cursor: "pointer" }}
          onClick={() => setMode("login")}
        >
          Voltar
        </p>
      </div>
    </>
  );
};

export default NoPasswordForm;
