import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import RedirectCounter from "./RedirectCounter";

import "../../css/login.css";

const AccessPage: FC = () => {
  const { TokenLogin } = useAuth();
  const navigate = useNavigate();
  const { token } = useParams() as { token: string };
  const [mode, setMode] = useState<"Logginin" | "Logged" | "Error">("Logginin");

  useEffect(() => {
    if (token) {
      TokenLogin(token)
        .then(() => {
          setMode("Logged");
          setTimeout(() => {
            window.history.replaceState({noPassword:true}, "", "/trocarSenha");
            window.history.go();
          }, 5000);
        })
        .catch((err) => setMode("Error"));
    }
  }, []);

  return (
    <div style={{ backgroundColor: "#56baed", height: "100%" }}>
      <div className="wrapper">
        <div id="formContent">
          {/* Icon */}
          <div>
            <img src="/images/neuroredes.png" id="icon" alt="User Icon" />
          </div>
          <h1 style={{ fontFamily: "sans-serif", color: "#AAA" }}>
            {mode === "Logginin" && "Autenticando..."}
            {mode === "Logged" && "Autorizado."}
            {mode === "Error" && "Seu acesso expirou."}
          </h1>
          <div>{mode === "Logged" && <RedirectCounter />}</div>

          {/* Remind password */}
          <div
            id="formFooter"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <p
              className="underlineHover"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Voltar à tela inicial
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessPage;
