import { useRegisterEvents, useSigma } from 'react-sigma-v2';
import { FC, useEffect } from 'react';

/**
 * Control the graphs's events
 * @param param0
 * @returns the controller
 */
const GraphEventsController: FC<{
  setHoveredNode: (node: string | null) => void;
  setHoveredEdge: (edge: string | null) => void;
  setSelectedNode: (node: string | null) => void;
  setSelectedEdge: (edge: string | null) => void;
  children?: React.ReactNode;
}> = ({
  setHoveredNode,
  setHoveredEdge,
  setSelectedNode,
  setSelectedEdge, // NOT USED. TODO: Implement the edge selection
  children,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();
  var draggedNode: string | null;
  var hoveredEdge: string | null;

  /**
   * It return the mouse pointer element
   * @returns mouse pointer element
   */
  function getMouseLayer() {
    return document.querySelector('.sigma-mouse');
  }

  /**
   * Register all the graph events
   */
  useEffect(() => {
    registerEvents({
      // Select a shown node if clicked
      clickNode({ node }) {
        if (!graph.getNodeAttribute(node, 'hidden')) {
          setSelectedNode(node);
          setSelectedEdge(null);
        }
      },
      enterNode({ node }) {
        if (draggedNode) {
          return;
        }
        setHoveredNode(node);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add('mouse-pointer');
      },
      leaveNode() {
        if (draggedNode) {
          return;
        }
        setHoveredNode(null);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove('mouse-pointer');
      },
      clickEdge({ edge }) {
        if (!graph.getEdgeAttribute(edge, 'hidden')) {
          setSelectedNode(null);
        }
      },
      // If a edge is hovered, create/set an attribute called "hovered" as true
      // It is a hack used in "drawEdgeLabel" to draw the edge label
      enterEdge(e) {
        if (draggedNode) {
          return;
        }
        setHoveredEdge(e.edge);
        hoveredEdge = e.edge;
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add('mouse-pointer');
        sigma.getGraph().setEdgeAttribute(e.edge, 'hovered', true);
      },
      // If a edge is unhovered, set an attribute called "hovered" as false
      // It is a hack used in "drawEdgeLabel" to draw the edge label
      leaveEdge(e) {
        if (draggedNode) {
          return;
        }
        setHoveredEdge(null);
        hoveredEdge = null;
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove('mouse-pointer');
        sigma.getGraph().setEdgeAttribute(e.edge, 'hovered', false);
      },
      // Set node as dragged if pressed
      downNode({ node }) {
        if (draggedNode) {
          return;
        }
        draggedNode = node;
      },
      mouseup(e) {
        draggedNode = null;
      },
      // Override the graph's bounding box
      mousedown(e) {
        if (!sigma.getCustomBBox()) {
          sigma.setCustomBBox(sigma.getBBox());
        }
      },
      mousemove(e) {
        // Everytime that an edge is hovered, update its parameters hover_x and hover_y wuth the mouse position
        // It is a hack used in "drawEdgeLabel" to draw the edge in the correct position
        if (hoveredEdge) {
          sigma.getGraph().setEdgeAttribute(hoveredEdge, 'hover_x', e.x);
          sigma.getGraph().setEdgeAttribute(hoveredEdge, 'hover_y', e.y);
        }
        if (!draggedNode) {
          return;
        }

        // Get new position of node
        const pos = sigma.viewportToGraph(e);

        graph.setNodeAttribute(draggedNode, 'x', pos.x);
        graph.setNodeAttribute(draggedNode, 'y', pos.y);

        // Prevent sigma to move camera:
        e.preventSigmaDefault();
        e.original.preventDefault();
        e.original.stopPropagation();
      },
      // Cancel selection if the stage is clicked
      clickStage() {
        setSelectedNode(null);
        setSelectedEdge(null);
      },
    });
  }, []);

  return <>{children}</>;
};

export default GraphEventsController;
