import { useSigma } from "react-sigma-v2";
import { FC, useEffect } from "react";
import { keyBy, omit } from "lodash";
import { Dataset, FiltersState, GraphStyle, Question } from "./types";
import noverlap from "graphology-layout-noverlap";
import * as API from "../../services/survey.service";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";

/**
 * Control the graph's data
 * @param param0
 * @returns the controller
 */
const GraphDataController: FC<{
  dataset: Dataset;
  questions: Record<string, Question>;
  setQuestions: (questions: Record<string, Question> | null) => void;
  graphStyle: GraphStyle;
  currentQuestion: string | null;
  filters: FiltersState;
  selectedNode: string | null;
  setSelectedNode: (node: string | null) => void;
  colorize: string;
  setEdgesPerSynchrony: (edgesPerSynchrony: Record<string, number>) => void;
  children?: React.ReactNode;
}> = ({
  dataset,
  questions,
  setQuestions,
  graphStyle,
  currentQuestion,
  filters,
  selectedNode,
  setSelectedNode,
  colorize,
  setEdgesPerSynchrony,
  children,
}) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const { accessToken } = useAuth();
  const { surveyId } = useParams() as { surveyId: string };

  /**
   * Forms a grid based on the screen size to position each department
   * @returns grid of positions
   */
  function clusteringByDepts() {
    var nareas = dataset.depts.length;

    var screenheight = sigma.getDimensions().height;
    var screenwidth =
      dataset.nodes.length <= 20 ? screenheight : sigma.getDimensions().width;

    // That formula makes the grid as proportional as possible to the screen dimension
    var horizontal_nodes = Math.ceil(
      Math.sqrt((screenwidth * nareas) / screenheight)
    );
    var vertical_nodes = Math.ceil(nareas / horizontal_nodes);

    var horizontal_spacing = screenwidth / (horizontal_nodes + 1);
    var vertical_spacing = screenheight / (vertical_nodes + 1);

    var cluster_pos: Record<string, { x: number; y: number }> = {};
    for (let j = 0; j < vertical_nodes; ++j) {
      for (let i = 0; i < horizontal_nodes; ++i) {
        cluster_pos[String(1 + i + j * horizontal_nodes)] = {
          x: (i + 1) * horizontal_spacing,
          y: (j + 1) * vertical_spacing,
        };
      }
    }
    return cluster_pos;
  }

  /**
   * Feed graph with the new dataset's node
   */
  useEffect(() => {
    if (!graph || !dataset) return;
    graph.clear();
    const depts = keyBy(dataset.depts, "key");
    const deptsStyle = keyBy(graphStyle.depts_style, "key");
    const cluster_pos = clusteringByDepts();
    dataset.nodes.forEach((node) => {
      graph.addNode(node.key, {
        ...node,
        ...omit(depts[node.dept], "key"),
        size: graphStyle.node_size,
        color: deptsStyle[node.dept].color,
        x: cluster_pos[node.dept].x,
        y: cluster_pos[node.dept].y,
      });
    });
    // Since nodes of the same department were positioned in the same coordinate it is necessary to reduce the overlap.
    // This command iteratively moves nodes in order to obtain the no-overlap configuration. However, such method
    // is limited by a number of iteration, so, it isn't perfect but doesn't take much processing.
    noverlap.assign(graph);
  }, [graph, dataset]);

  useEffect(() => {
    if (!graph || !dataset || !graphStyle) return;

    const nodeStyle = keyBy(graphStyle.depts_style, "key");
    const attrs = keyBy(dataset.attributes, "key");
    graph.forEachNode((node) => {
      var attr = graph.getNodeAttribute(node, "dept");
      if (colorize !== "depts") {
        var attr_list = attrs[colorize].values;
        var index = colorize.substring("Atributo ".length);
        var nodeAttr = graph.getNodeAttribute(node, "attributes")[+index - 2];
        attr = attr_list.findIndex((attr) => attr.key === nodeAttr) + 1;
      }
      

      graph.setNodeAttribute(node, "size", graphStyle.node_size);
      graph.setNodeAttribute(node, "color", nodeStyle[attr].color);
    });
    localStorage.setItem(dataset.company, JSON.stringify(graphStyle));
  }, [graphStyle, colorize]);

  /**
   * Feed the graph with the new dataset's edge. It changes everytime a new question is selected.
   */
  useEffect(() => {
    if (!graph || !dataset || !currentQuestion || !graphStyle || !accessToken)
      return;
    // Every time that a new question is selected, all the edges are rebuild.
    graph.clearEdges();
    const synchrony = keyBy(graphStyle.synchrony_style, "key");
    const nodesmap = keyBy(dataset.nodes, "id");
    var drawEdgesPromise;
    if (!questions[currentQuestion]) {
      drawEdgesPromise = API.getSurveyQuestion(
        surveyId,
        accessToken,
        String(+currentQuestion + 1)
      )
        .then((res) => res.data)
        .then((question: Question) => {
          var newQuestions = questions;
          newQuestions[currentQuestion] = question;
          setQuestions(newQuestions);
        });
    } else {
      drawEdgesPromise = Promise.resolve();
    }

    drawEdgesPromise.then(() => {
      questions[currentQuestion].edges
        .filter(
          (edge) =>
            edge.synchrony !== 0 &&
            nodesmap[edge.source] &&
            nodesmap[edge.target]
        )
        .forEach((edge) => {
          graph.addEdge(nodesmap[edge.source].key, nodesmap[edge.target].key, {
            size: graphStyle.edge_thickness,
            synchrony: synchrony[edge.synchrony].key,
            synchronyType: synchrony[edge.synchrony].type,
            label: synchrony[edge.synchrony].label,
            answerST: edge.answerST,
            answerTS: edge.answerTS,
            color: synchrony[edge.synchrony].color,
          });
        });

      const index: Record<string, number> = {};
      questions[currentQuestion].edges
        .filter(
          (edge) =>
            edge.synchrony !== 0 &&
            nodesmap[edge.source] &&
            nodesmap[edge.target]
        )
        .forEach(
          (edge) =>
            (index[String(edge.synchrony)] =
              (index[String(edge.synchrony)] || 0) + 1)
        );
      setEdgesPerSynchrony(index);
    });
  }, [graph, dataset, currentQuestion]);

  useEffect(() => {
    if (!graph || !dataset || !currentQuestion || !graphStyle) return;
    graph.forEachEdge((edge) => {
      const synchrony = keyBy(graphStyle.synchrony_style, "key");
      graph.setEdgeAttribute(
        edge,
        "color",
        synchrony[graph.getEdgeAttribute(edge, "synchrony")].color
      );
      graph.setEdgeAttribute(edge, "size", graphStyle.edge_thickness);
    });
  }, [graphStyle]);

  /**
   * Apply filters to graph.
   */
  useEffect(() => {
    const { synchrony, depts, attribute_tags } = filters;
    graph.forEachNode((node, data) => {
      var visible = true;

      // Hide if any attribute of the node were unselected
      data.attributes.forEach((tag: string) => {
        visible = visible && attribute_tags[tag];
      });

      let hidden = !depts[data.dept] || !visible;

      // Unselect the node if it were to be hidden
      if (hidden && node === selectedNode) {
        setSelectedNode(null);
      }
      graph.setNodeAttribute(node, "hidden", hidden);
    });

    graph.forEachEdge((edge, data) => {
      var visible = true;
      // Hide the edge, if any of its nodes were hidden
      graph.extremities(edge).forEach((node) => {
        visible = visible && !graph.getNodeAttribute(node, "hidden");
      });
      graph.setEdgeAttribute(
        edge,
        "hidden",
        !visible || !synchrony[data.synchrony]
      );
    });
  }, [graph, filters]);

  return <>{children}</>;
};

export default GraphDataController;
