import { FC, useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { getCompany } from "../../services/company.service";

interface Props {
  companyId: number;
}

const CompanyCell: FC<Props> = ({ companyId }) => {
  const { accessToken } = useAuth();
  const [company, setCompany] = useState<string | null>("");
  const [name, setName] = useState<string | null>("");

  useEffect(() => {
    getCompany(companyId, accessToken as string).then(({ data }) => {
      setCompany(data.nome);
      setName(data.responsavel_nome);
    });
  }, []);

  if (!name) {
    return null;
  }

  return (
    <>
      {name}
      <br />({company})
    </>
  );
};

export default CompanyCell;
