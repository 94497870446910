import React, { ReactNode } from "react";

interface LeftPanelType {
  children?: ReactNode;
  isOpenLeftPanel: boolean;
  toggleLeftPanel: () => void;
}

export default function LeftPanel(props: LeftPanelType) {
  return (
    <>
      {!props.isOpenLeftPanel && (

        <div onClick={(e) => e.stopPropagation()} className="left-panel-content hoverable">
          {props.children}
        </div>

      )
      }
    </>
  );
}
