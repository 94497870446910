import axios from "axios";
import { API_URL } from './service';

export const getCompany = (companyId: number, token: string) => {
    return axios.get(API_URL + `company/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};
