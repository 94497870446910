import { FC } from "react";

import { useAuth } from "../contexts/auth";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

const Routes: FC = () => {
  const { loading, signed } = useAuth();

  if (loading) {
    return <></>;
  }

  return signed ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Routes;
