import { FC } from "react";
import { useNavigate } from "react-router-dom";
import CompanyCell from "./CompanyCell";

interface Props {
  survey: any;
  selection: number[];
  setSelection: (selection: number[]) => void;
}

const SurveyRow: FC<Props> = ({ survey, selection, setSelection }) => {
  const navigate = useNavigate();

  const startDate = new Date(Date.parse(survey.data_inicio)).toLocaleDateString(
    "pt-BR"
  );

  const endDate = new Date(Date.parse(survey.data_fim)).toLocaleDateString(
    "pt-BR"
  );

  return (
    <tr>
      <td className="label blue-grey darken-4"></td>
      <td>
        <label>
          {(survey.ativo !== 1) && (selection.length !== 2 || selection.includes(survey.id) )&& (
            <input
              type="checkbox"
              onChange={(event) => {
                if (event.currentTarget.checked) {
                  if (!selection.includes(survey.id)) {
                    setSelection([...selection, survey.id]);
                  }
                } else {
                  setSelection(selection.filter((id) => id !== survey.id));
                }
              }}
              id={`synchrony-${survey.id}`}
              name="checkbox-research"
            />
          )}
          <span></span>
        </label>
      </td>
      <td>{survey.id}</td>
      <td>{survey.titulo}</td>
      <td>{<CompanyCell companyId={survey.empresa_id} />}</td>
      <td>
        {startDate}
        <br />
        (limite: {endDate})
      </td>
      {survey.ativo === 1 && <td>Aberta</td>}
      {survey.ativo === 2 && <td>Encerrada</td>}
      {survey.ativo === 1 && (
        <td className="pequeno">
          <button
            className="waves-effect waves-light btn tooltipped"
            data-position="top"
            data-tooltip="Visualizar"
            onClick={() => navigate(`/pesquisa/${survey.id}`)}
            disabled
          >
            <i className="material-icons center">visibility_off</i>
          </button>
        </td>
      )}
      {survey.ativo === 2 && (
        <td className="pequeno">
          <button
            className="waves-effect waves-light blue-grey darken-4 btn tooltipped"
            data-position="top"
            data-tooltip="Visualizar"
            onClick={() => navigate(`/pesquisa/${survey.id}`)}
          >
            <i className="material-icons center">remove_red_eye</i>
          </button>
        </td>
      )}
    </tr>
  );
};

export default SurveyRow;
