import React, { FC, ReactNode, useContext, useEffect, useState } from "react";

import * as API from "../services/auth.service";

interface AuthContextData {
  loading: boolean; // Don't show anything until defined which route to use.
  signed: boolean; // Used to define public or private routes.
  viewer: API.Viewer | null;
  accessToken: string | null;
  PasswordLogin(
    email: string,
    password: string,
    keepConnected: boolean
  ): Promise<boolean>;
  TokenLogin(aToken: string): Promise<boolean>;
  Logout(): void;
}

const AuthContext = React.createContext<AuthContextData>({} as AuthContextData);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [viewer, setViewer] = useState<API.Viewer | null>(null);
  const [accessToken, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  // Read session from storage.
  useEffect(() => {
    const storagedViewer =
      localStorage.getItem("@App:viewer") ||
      sessionStorage.getItem("@App:viewer");
    const storagedToken =
      localStorage.getItem("@App:token") ||
      sessionStorage.getItem("@App:token");

    // If exists, save as states.
    if (storagedViewer && storagedToken) {
      setToken(storagedToken);
      setViewer(JSON.parse(storagedViewer));
    } else {
      Logout();
    }

    setLoading(false);
  }, []);

  // Start a session using email and password.
  const PasswordLogin = async (
    email: string,
    password: string,
    keepConnected: boolean
  ) => {
    // Call API.
    const data = await API.login(email, password);

    // Check the response.
    if (!data) {
      return false;
    }

    // Set the viewer and token to session.
    const { viewer, access_token } = data;
    if (keepConnected) {
      localStorage.setItem("@App:viewer", JSON.stringify(viewer));
      localStorage.setItem("@App:token", access_token.token);
    } else {
      sessionStorage.setItem("@App:viewer", JSON.stringify(viewer));
      sessionStorage.setItem("@App:token", access_token.token);
    }
    setToken(access_token.token);
    setViewer(viewer);
    return true;
  };

  // Start a session using an access token.
  const TokenLogin = async (aToken: string) => {
    // Call API.
    const viewer = await API.getCurrentViewer(aToken);

    // Check the response.
    if (!viewer) {
      return false;
    }

    // Set the viewer and token to session.
    sessionStorage.setItem("@App:token", aToken);
    sessionStorage.setItem("@App:viewer", JSON.stringify(viewer));
    return true;
  };

  // Closes the session.
  const Logout = () => {
    localStorage.removeItem("@App:viewer");
    localStorage.removeItem("@App:token");
    sessionStorage.removeItem("@App:viewer");
    sessionStorage.removeItem("@App:token");
    setToken(null);
    setViewer(null);
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        signed: Boolean(viewer),
        viewer,
        accessToken,
        PasswordLogin,
        TokenLogin,
        Logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
