import { pickBy } from "lodash";
import { FC } from "react";
import { BiReset } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import Modal from "../../components/modal";
import { Attribute, Dataset, Department, GraphStyle } from "./types";
import { useAuth } from "../../contexts/auth";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AiOutlineConsoleSql } from "react-icons/ai";
import * as API from "../../services/survey.service";
import { getRandomColor } from "./utils";

const ConfigModal: FC<{
  colorize: string;
  setColorize: (c: string) => void;
  dataset: Dataset;
  graphStyle: GraphStyle;
  setGraphStyle: (g: GraphStyle) => void;
  isConfigModalOpen: boolean;
  configModalToggle: () => void;
}> = ({
  colorize,
  setColorize,
  dataset,
  graphStyle,
  setGraphStyle,
  isConfigModalOpen,
  configModalToggle,
}) => {
  const { accessToken } = useAuth();

  function showSelectedCategory() {
    var selected: any = dataset.depts;
    var label = "value";

    if (colorize !== "depts") {
      selected = dataset.attributes.filter((a) => a.key === colorize)[0].values;
      label = "key";
    }

    return selected.map((dept: any, i: any) => {
      if (!graphStyle.depts_style[i + 1]) {
        graphStyle.depts_style[i + 1] = {
          key: ((i as number) + 1).toString(),
          color: getRandomColor(),
        };
      }

      return (
        <div className="row" key={dept.key + "_outer_container"}>
          <div
            className="col s9"
            style={{ paddingTop: "7px" }}
            key={dept.key + "+_label_legend_container"}
          >
            <label
              id={dept.key + "_label_legend"}
              key={dept.key + "_label_legend_value"}
            >
              {dept[label as any]}
            </label>
          </div>
          <div className="col s3" key={dept.key + "_input_legend_container"}>
            <input
              type="color"
              id={dept.key + "_input_legend"}
              key={dept.key + "_input_legend"}
              name="deptColor"
              onChange={(event) => {
                setGraphStyle({
                  ...graphStyle,
                  depts_style: {
                    ...graphStyle.depts_style,
                    [i + 1]: {
                      ...graphStyle.depts_style[i + 1],
                      color: event.target.value,
                    },
                  },
                });
              }}
              value={graphStyle.depts_style[i + 1].color}
            />
          </div>
        </div>
      );
    });
  }

  return (
    <Modal
      isOpen={isConfigModalOpen}
      toggle={configModalToggle}
      id="config-label-modal"
      data-html2canvas-ignore="false"
    >
      <div className="modal-head">
        <h2>Personalizar Legenda</h2>
        <button
          style={{ cursor: "pointer", border: "none" }}
          onClick={configModalToggle}
          title="Fechar"
        >
          <GrClose />
        </button>
      </div>

      <div className="modal-body">
        <FormControl fullWidth>
          <InputLabel id="colorize-select-label">Categoria</InputLabel>
          <Select
            labelId="colorize-select-label"
            id="colorize-select"
            value={colorize}
            label="Colorizar"
            onChange={(event) => {
              setColorize(event.target.value);
            }}
            className="colorize-select"
          >
            <MenuItem
              key="colorize_depts"
              className="colorize-select"
              value={"depts"}
            >
              Atributo 1
            </MenuItem>
            {dataset.attributes.map((a, i) => {
              return (
                <MenuItem
                  key={"Colorize" + a.key}
                  className="colorize-select"
                  value={a.key}
                >
                  {a.key}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <br />
        <br />

        <div className="row">
          <div className="col" style={{ display: "flex" }}>
            <h2 id="node_thickness_label_legend">Tamanho dos nós: </h2>
            <span
              id="node_thickness_value_legend"
              style={{
                marginLeft: "1em",
                marginTop: "-4px",
                fontSize: "1.2em",
              }}
            >
              {graphStyle.node_size}
            </span>
          </div>
          <div className="col s7">
            <input
              type="range"
              min="1"
              max="100"
              id={"node_thickness_input_legend"}
              name="node_thickness_legend"
              onChange={(event) => {
                setGraphStyle({
                  ...graphStyle,
                  node_size: Number(event.target.value),
                });
              }}
              value={graphStyle.node_size}
            />
          </div>
        </div>

        <div className="row">
          <div className="col" style={{ display: "flex" }}>
            <h2 id="edge_thickness_label_legend">Espessura da aresta: </h2>
            <span
              id="edge_thickness_value_legend"
              style={{
                marginLeft: "1em",
                marginTop: "-4px",
                fontSize: "1.2em",
              }}
            >
              {graphStyle.edge_thickness}
            </span>
          </div>
          <div className="col s7">
            <input
              type="range"
              min="1"
              max="10"
              id={"edge_thickness_input_legend"}
              name="edge_thickness_legend"
              onChange={(event) => {
                setGraphStyle({
                  ...graphStyle,
                  edge_thickness: Number(event.target.value),
                });
              }}
              value={graphStyle.edge_thickness}
            />
          </div>
        </div>

        <div className="row" style={{ marginTop: "2em" }}>
          <div className="col s7" style={{ paddingRight: "2rem" }}>
            <div className="row">
              <div className="col s12">
                <h2>Categoria</h2>
                <div className="divider" style={{ margin: "1rem 0px" }}></div>
              </div>
            </div>
            {showSelectedCategory()}
          </div>
          <div
            className="col"
            style={{ borderLeft: "0px solid gray", paddingLeft: "1rem" }}
          >
            <div className="row">
              <div className="col s12">
                <h2>Conexões</h2>
                <div className="divider" style={{ margin: "1rem 0px" }}></div>
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <h2>Síncrono</h2>
              </div>
            </div>
            {Object.keys(
              pickBy(graphStyle.synchrony_style, (synchrony) => {
                return synchrony.type === "Síncrono";
              })
            ).map((key, index) => {
              return (
                <div className="row" key={key + "_synchrony_outer_container"}>
                  <div className="col s9">
                    <label id={key + "_synchrony_label_legend"}>
                      {graphStyle.synchrony_style[key as any].label}
                    </label>
                  </div>
                  <div className="col s3">
                    <input
                      type="color"
                      id={key + "_synchrony_input_legend"}
                      name="synchronyColor"
                      onChange={(event) => {
                        setGraphStyle({
                          ...graphStyle,
                          synchrony_style: {
                            ...graphStyle.synchrony_style,
                            [key]: {
                              ...graphStyle.synchrony_style[key as any],
                              color: event.target.value,
                            },
                          },
                        });
                      }}
                      value={graphStyle.synchrony_style[key as any].color}
                    />
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="col s12">
                <h2>Assíncrono</h2>
              </div>
            </div>
            {Object.keys(
              pickBy(graphStyle.synchrony_style, (synchrony) => {
                return synchrony.type === "Assíncrono";
              })
            ).map((key, index) => {
              return (
                <div className="row" key={key + "_synchrony_outer_container"}>
                  <div className="col s9">
                    <label id={key + "_synchrony_label_legend"}>
                      {graphStyle.synchrony_style[key as any].label}
                    </label>
                  </div>
                  <div className="col s3">
                    <input
                      type="color"
                      id={key + "_synchrony_input_legend"}
                      name="synchronyColor"
                      onChange={(event) => {
                        setGraphStyle({
                          ...graphStyle,
                          synchrony_style: {
                            ...graphStyle.synchrony_style,
                            [key]: {
                              ...graphStyle.synchrony_style[key as any],
                              color: event.target.value,
                            },
                          },
                        });
                      }}
                      value={graphStyle.synchrony_style[key as any].color}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          style={{ cursor: "pointer", padding: "0.8em" }}
          onClick={() => {
            if (!accessToken) return;
            API.getLegend(accessToken)
              .then((res) => res.json())
              .then((graphStyle: GraphStyle) => {
                // Save legend in the localStorage
                localStorage.setItem(
                  dataset.company,
                  JSON.stringify(graphStyle)
                );
                setGraphStyle(graphStyle);
              });
          }}
          title="Redefinir tudo ao padrão original"
        >
          <BiReset /> Redefinir tudo
        </button>
      </div>
    </Modal>
  );
};

export default ConfigModal;
