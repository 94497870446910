import { FC, useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { SurveyInfo } from './types';
import { GraphStyle, SynchronyStyle } from '../Graph/types';
import { useAuth } from '../../contexts/auth';
//import { keyBy } from 'lodash';
import { API_URL } from '../../services/service';
interface Props {
  surveyInfoA: SurveyInfo | null;
  selectedQuestionA: string;
  surveyInfoB: SurveyInfo | null;
  selectedQuestionB: string;
}

const SurveyBarChart: FC<Props> = ({
  surveyInfoA,
  selectedQuestionA,
  surveyInfoB,
  selectedQuestionB,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const { accessToken } = useAuth();
  const [synchStyle, setSynchStyle] = useState<SynchronyStyle[] | null>(null);
  const [data, setData] = useState<any>(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  const labels = [
    'Alto',
    'Médio',
    'Baixo',
    'Alto/Baixo',
    'Médio/Nulo',
    'Alto/Nulo',
    'Nulo',
  ];

  useEffect(() => {
    fetch(API_URL + 'survey/legend', {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => res.json())
      .then((graphStyle: GraphStyle) => {
        setSynchStyle(graphStyle.synchrony_style);
      });
  }, []);

  useEffect(() => {
    if (!surveyInfoA) return;
    if (!surveyInfoB) return;
    if (!synchStyle) return;
    let dA = surveyInfoA.questions[+selectedQuestionA - 1]?.values;
    let dB = surveyInfoB.questions[+selectedQuestionB - 1]?.values;
    if (!dA) return;
    if (!dB) return;

    console.log('JAFLKJHAFLKJHALF');
    //let color = Object.keys(keyBy(synchStyle, "color"));

    setData({
      labels,
      datasets: [
        {
          label: surveyInfoA?.survey,
          data: [
            dA.high_synchrony,
            dA.mid_synchrony,
            dA.low_synchrony,
            dA.high_low_asynchrony,
            dA.mid_null_asynchrony,
            dA.high_null_asynchrony,
            dA.null,
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: surveyInfoB?.survey,
          data: [
            dB.high_synchrony,
            dB.mid_synchrony,
            dB.low_synchrony,
            dB.high_low_asynchrony,
            dB.mid_null_asynchrony,
            dB.high_null_asynchrony,
            dB.null,
          ],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    });
  }, [
    surveyInfoA,
    selectedQuestionA,
    surveyInfoB,
    selectedQuestionB,
    synchStyle,
  ]);

  if (!data) return <></>;
  return <Bar options={options} data={data} />;
};

export default SurveyBarChart;
