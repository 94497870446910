export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function round(value: number, precision: number) {
  var factor = Math.pow(10, precision);
  return Math.round(100 * value * factor) / factor;
}
