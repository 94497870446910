import { FC } from "react";
import { MdHelp } from "react-icons/md";

import { QuestionInfo } from "./types";
import Panel from "./Panel";

/**
 * Panel used to select the question
 * @param param0 
 * @returns the graphical element
 */
const QuestionsPanel: FC<{
  questions: QuestionInfo[];
  currentQuestion: string | null;
  setCurrentQuestion: (currentQuestion: string) => void;
}> = ({ questions, currentQuestion, setCurrentQuestion }) => {
  /**
   * Draw the graphical element
   */
  return (
    // Draw panel header
    <Panel
      title={
        <>
          {" "}
          <MdHelp className="text-muted" /> Perguntas{" "}
        </>
      }
    >
      {/* Draw question list */}
      <ul id="questionList">
        {questions
          .filter((q) => q.label !== "")
          .map((question, index) => {
            let questionSelected = Number(question.key) === Number(currentQuestion) + 1
            return (
              <li
                className="caption-row"
                key={question.key}
                style={{ textAlign: "justify" }}
              >
                <input
                  type="checkbox"
                  checked={questionSelected} // index starts with 1
                  onChange={() => {
                    setCurrentQuestion(String(index));
                  }}
                  id={`question${question.key}`}
                />
                <label
                  htmlFor={`question${question.key}`}
                  style={
                    Number(question.key) === Number(currentQuestion) + 1 ?
                      { color: "#616161 ", fontWeight: "bold" }
                      : {}}
                >
                  <span
                    className="circle"
                    style={
                      questionSelected ? {
                        borderColor: "#616161 ",
                        background: "rgb(00,99,255,0)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: "1em"
                      } : {}
                    }
                  >
                    {question.key}
                  </span>
                  <div className="node-label">
                    <span>{question.label}</span>
                  </div>
                </label>
              </li>
            );
          })}
      </ul>
    </Panel >
  );
};

export default QuestionsPanel;
