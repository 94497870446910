import { useSigma } from 'react-sigma-v2';
import { FC, useEffect } from 'react';
import { drawNodeHover } from './canvas-utils';
import useDebounce from './use-debounce';

const NODE_FADE_COLOR = '#eee';
const EDGE_FADE_COLOR = '#eee';

/**
 * Control the graph's behaviour
 * @param hoveredNode
 * @param hoveredEdge
 * @param selectedNode
 * @param selectedEdge
 * @param children
 * @returns the controller
 */
const GraphSettingsController: FC<{
  hoveredNode: string | null;
  hoveredEdge: string | null;
  selectedNode: string | null;
  selectedEdge: string | null;
  children?: React.ReactNode;
}> = ({ children, hoveredNode, hoveredEdge, selectedNode, selectedEdge }) => {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  // Here we debounce the value to avoid having too much highlights refresh when
  // moving the mouse over the graph:
  const debouncedHoveredNode = useDebounce(hoveredNode, 40);
  const debouncedHoveredEdge = useDebounce(hoveredEdge, 40);

  /**
   * Initialize here settings that require to know the graph and/or the sigma
   * instance:
   */
  useEffect(() => {
    sigma.setSetting('hoverRenderer', (context, data, settings) => {
      // If a node is selected, it will only show its label
      if (selectedNode) {
        if (data.key === selectedNode) {
          drawNodeHover(
            context,
            { ...sigma.getNodeDisplayData(data.key), ...data },
            settings
          );
        }
      } else {
        drawNodeHover(
          context,
          { ...sigma.getNodeDisplayData(data.key), ...data },
          settings
        );
      }
    });
  }, [sigma, graph, selectedNode]);

  /**
   * Update node and edge reducers when a node or edge is hovered
   */
  useEffect(() => {
    // The nodeReducer is executed when a node is hovered
    sigma.setSetting('nodeReducer', (node, data) => {
      // if a node is selected do not allow other nodes to be hovered
      var debouncedNode = selectedNode ? selectedNode : debouncedHoveredNode;
      var debouncedEdge = selectedEdge ? selectedEdge : debouncedHoveredEdge;
      var visibleNodeNeigh = false;
      if (!graph.getNodeAttribute(node, 'hidden')) {
        if (graph.hasEdge(node, debouncedNode)) {
          visibleNodeNeigh =
            visibleNodeNeigh ||
            graph.edges(node, debouncedNode).findIndex((edge) => {
              return !graph.getEdgeAttribute(edge, 'hidden');
            }) >= 0;
        }

        if (graph.hasEdge(debouncedNode, node)) {
          visibleNodeNeigh =
            visibleNodeNeigh ||
            graph.edges(debouncedNode, node).findIndex((edge) => {
              return !graph.getEdgeAttribute(edge, 'hidden');
            }) >= 0;
        }
      }

      // Node behaviour based on node hover
      if (debouncedNode) {
        if (node === debouncedNode || visibleNodeNeigh) {
          return { ...data, zIndex: -1 };
        } else {
          return {
            ...data,
            zIndex: -1,
            label: '',
            hidden: selectedNode ? true : false,
            color: NODE_FADE_COLOR,
            image: null,
            highlighted: false,
          };
        }
      }

      // Node behaviour based on edge hover
      if (debouncedEdge) {
        if (graph.hasExtremity(debouncedEdge, node)) {
          return { ...data, zIndex: -1 };
        } else {
          return {
            ...data,
            zIndex: -1,
            label: '',
            color: NODE_FADE_COLOR,
            image: null,
            highlighted: false,
          };
        }
      }

      return data;
    });

    // The nodeReducer is executed when an edge is hovered
    sigma.setSetting('edgeReducer', (edge, data) => {
      // if a node is selected do not allow edges to be hovered
      var debouncedNode = selectedNode ? selectedNode : debouncedHoveredNode;
      var debouncedEdge = selectedEdge ? selectedEdge : debouncedHoveredEdge;

      // Edge behaviour based on node hover
      if (debouncedNode) {
        if (graph.hasExtremity(edge, debouncedNode)) {
          return { ...data };
        } else {
          return { ...data, color: EDGE_FADE_COLOR, hidden: true };
        }
      }

      // Node behaviour based on node hover
      if (debouncedEdge) {
        if (edge === debouncedEdge) {
          return { ...data, zIndex: 1 };
        } else {
          return {
            ...data,
            zIndex: 1,
            hidden: true,
            highlighted: false,
          };
        }
      }

      return data;
    });
  });
  return <>{children}</>;
};

export default GraphSettingsController;
