import { useState } from "react";

export default function useConfigModal() {
  const [isConfigModalOpen, setConfigModalisOpen] = useState(false);

  const configModalToggle = () => {
    setConfigModalisOpen(!isConfigModalOpen);
  };

  return {
    isConfigModalOpen: isConfigModalOpen,
    configModalToggle,
  };
}
