import { FC, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { SurveyInfo } from './types';
import { GraphStyle, SynchronyStyle } from '../Graph/types';
import { useAuth } from '../../contexts/auth';
import { keyBy } from 'lodash';
import { API_URL } from '../../services/service';

interface Props {
  surveyInfo: SurveyInfo | null;
  selectedQuestion: string;
}

const SurveyPieChart: FC<Props> = ({ surveyInfo, selectedQuestion }) => {
  const [data, setData] = useState<any>(null);
  const [synchStyle, setSynchStyle] = useState<SynchronyStyle[] | null>(null);
  const { accessToken } = useAuth();

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    fetch(API_URL + 'survey/legend', {
      method: 'GET',
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => res.json())
      .then((graphStyle: GraphStyle) => {
        setSynchStyle(graphStyle.synchrony_style);
      });
  }, []);

  useEffect(() => {
    if (!surveyInfo) return;
    if (!synchStyle) return;
    let surveyData = surveyInfo.questions[+selectedQuestion - 1]?.values;
    if (!surveyData) return;

    let backgroundColor = Object.keys(keyBy(synchStyle, 'color'));

    console.log(surveyData);

    setData({
      labels: [
        'Alto',
        'Médio',
        'Baixo',
        'Alto/Baixo',
        'Médio/Nulo',
        'Alto/Nulo',
        'Nulo',
      ],
      datasets: [
        {
          label: 'Porcentagem',
          data: [
            surveyData.high_synchrony,
            surveyData.mid_synchrony,
            surveyData.low_synchrony,
            surveyData.high_low_asynchrony,
            surveyData.mid_null_asynchrony,
            surveyData.high_null_asynchrony,
            surveyData.null,
          ],
          backgroundColor: backgroundColor,
        },
      ],
    });
  }, [surveyInfo, selectedQuestion, synchStyle]);

  if (!data) return <></>;
  return <Pie data={data} />;
};

export default SurveyPieChart;
