import React, { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import moment from "moment";
import * as API from "../../services/survey.service";

import { SigmaContainer, ZoomControl, FullScreenControl } from "react-sigma-v2";
import "react-sigma-v2/lib/react-sigma-v2.css";
import { BiRadioCircleMarked, BiBookContent, BiReset } from "react-icons/bi";
import {
  BsArrowsFullscreen,
  BsCamera,
  BsFullscreenExit,
  BsZoomIn,
  BsZoomOut,
  BsGear,
  BsFileBarGraph,
} from "react-icons/bs";
import { GrClose, GrMail } from "react-icons/gr";

import { mapValues, keyBy, constant, pickBy, Dictionary } from "lodash";
import html2canvas from "html2canvas";

import GraphSettingsController from "./GraphSettingsController";
import GraphEventsController from "./GraphEventsController";
import GraphDataController from "./GraphDataController";
import DeptsPanel from "./DeptPanel";
import SearchField from "./SearchField";
import AttributePanel from "./AttributePanel";
import QuestionsPanel from "./QuestionPanel";
import SynchronyPanel from "./SynchronyPanel";
import StatisticsPanel from "./StatisticsPanel";

import Spinner from "./Spinner";
import { drawNodeLabel, drawEdgeLabel } from "./canvas-utils";
import {
  Attribute,
  Dataset,
  FiltersState,
  Tag,
  GraphStyle,
  Question,
} from "./types";

import Modal from "../../components/modal";
import LeftPanel from "../../components/leftPanel";
import RightPanel from "../../components/rightPanel";
import ReportModal from "../../components/reportModal";

import useConfigModal from "./use-configModal";
import useLeftPanel from "./use-leftPanel";
import useRightPanel from "./use-rightPanel";
import useReportModal from "./use-reportModal";

import { MdChevronLeft, MdChevronRight, MdOutlineCancel } from "react-icons/md";

import { Tooltip } from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfigModal from "./ConfigModal";

/**
 * Presents the graph and its controls
 */
const Graph: FC = () => {
  const { accessToken, viewer } = useAuth();
  const { surveyId } = useParams() as { surveyId: string };

  const printRef = React.useRef<HTMLDivElement>(null);
  const [showContents, setShowContents] = useState(false);
  const [dataResearchReady, setDataResearchReady] = useState(false);
  const [graphStyleReady, setGraphStyleReady] = useState(false);

  const [dataset, setDataset] = useState<Dataset | null>(null);
  const [questions, setQuestions] = useState<Record<string, Question> | null>(
    null
  );

  const [edgesPerSynchrony, setEdgesPerSynchrony] = useState<
    Record<string, number>
  >({});
  const [currentQuestion, setCurrentQuestion] = useState<string>("0");
  const [graphStyle, setGraphStyle] = useState<GraphStyle | null>(null);
  const [filtersState, setFiltersState] = useState<FiltersState>({
    depts: {},
    attribute_tags: {},
    synchrony: {},
  });
  const [hoveredNode, setHoveredNode] = useState<string | null>(null);
  const [hoveredEdge, setHoveredEdge] = useState<string | null>(null);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<string | null>(null);
  const [alltags] = useState<Tag[]>([]);

  const [colorize, setColorize] = useState<string>("depts");

  const { isConfigModalOpen, configModalToggle } = useConfigModal();
  const { isReportModalOpen, reportModalToggle } = useReportModal();
  const { isOpenLeftPanel, toggleLeftPanel } = useLeftPanel();
  const { isOpenRightPanel, toggleRightPanel } = useRightPanel();

  /**
   * This function saves the image
   */
  const handleDownloadImage = async () => {
    /**
     * Name format of the image file
     */
    function imageNaming() {
      const date = new Date();

      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let second = date.getSeconds();
      let currentDateTime = `${year}.${month}.${day} ${hour}h${minute}min${second}s`;
      let filename = `[${currentDateTime}] Pesquisa ${surveyId} - Pergunta ${
        Number(currentQuestion) + 1
      }`;

      if (selectedNode !== null) {
        filename = `${filename} (nó ${selectedNode})`;
      }
      return filename + ".jpg";
    }
    if (currentQuestion == null || surveyId == null) {
      return;
    }

    const element = printRef.current!;
    const canvas = await html2canvas(element, {});

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = imageNaming();

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  function reportTimeEstimative() {
    if (!dataset) return <></>;

    let datasetSize = dataset.nodes.length;

    if (datasetSize < 300) {
      return <>10 minutos</>;
    }

    if (datasetSize < 600) {
      return <>30 minutos</>;
    }

    return <>1 hora</>;
  }

  /**
   * Load data on mount.
   */
  useEffect(() => {
    if (surveyId && accessToken && currentQuestion) {
      // Load survey
      API.getSurveyGraph(surveyId, accessToken)
        .then((res) => res.data)
        .then((dataset: Dataset) => {
          dataset.attributes = dataset.attributes.filter(
            (attr) =>
              attr.values.length > 0 &&
              !(attr.values.length === 1 && attr.values.at(0)?.key === "")
          );

          dataset.nodes = dataset.nodes.map((node) => {
            return {
              ...node,
              attributes: node.attributes.filter((att) => {
                return att !== "";
              }),
            };
          });

          setDataset(dataset);
          API.getSurveyQuestion(
            surveyId,
            accessToken,
            String(+currentQuestion + 1)
          )
            .then((res) => res.data)
            .then((question: Question) => {
              setQuestions({ 0: question });
            });

          alltags.splice(0, alltags.length);
          dataset.attributes.forEach((attr: Attribute, i) => {
            attr.values.forEach((tag: Tag) => {
              alltags.push(tag);
            });
          });

          let gstyle = localStorage.getItem(dataset.company);
          let legendPromise;

          // Check if there is a legend in the local storage
          if (gstyle) {
            // Transform the cached legend into a promise
            legendPromise = new Promise<Response>((resolve) => {
              resolve(new Response(gstyle));
            });
          } else {
            //  Fetch data if not cached
            legendPromise = API.getLegend(accessToken);
          }
          legendPromise
            .then((res) => res.json())
            .then((graphStyle: GraphStyle) => {
              // Save legend in the localStorage
              localStorage.setItem(dataset.company, JSON.stringify(graphStyle));
              setGraphStyle(graphStyle);
              setFiltersState({
                depts: mapValues(keyBy(dataset.depts, "key"), constant(true)),
                attribute_tags: mapValues(
                  keyBy(alltags, "key"),
                  constant(true)
                ),
                synchrony: mapValues(
                  keyBy(graphStyle.synchrony_style, "key"),
                  constant(true)
                ),
              });
              requestAnimationFrame(() => setGraphStyleReady(true));
            });
          requestAnimationFrame(() => setDataResearchReady(true));
        });
    }
  }, []);

  /**
   * ESC cancels node selection.
   * TODO: edge selection
   */
  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      setSelectedNode(null);
      setSelectedEdge(null);
    });
  });

  // While data is not ready, show spinner
  if (!dataset) return <Spinner />;
  if (!graphStyle) return <Spinner />;
  if (!questions) return <Spinner />;

  return (
    <div
      id="app-root"
      className={showContents ? "show-contents" : ""}
      ref={printRef}
    >
      {/* Graph container */}
      <SigmaContainer
        graphOptions={{ type: "mixed" }}
        initialSettings={{
          labelRenderer: drawNodeLabel,
          edgeLabelRenderer: drawEdgeLabel,
          renderLabels: true,
          renderEdgeLabels: true,
          labelDensity: Infinity,
          labelRenderedSizeThreshold: -Infinity, // This is set in order to show label regardless of the camera zoom
          labelFont: "Lato, sans-serif",
          zIndex: true,
          enableEdgeClickEvents: true,
          enableEdgeHoverEvents: "debounce",
        }}
        className="react-sigma"
      >
        {/* Controllers */}
        <GraphSettingsController
          hoveredNode={hoveredNode}
          hoveredEdge={hoveredEdge}
          selectedNode={selectedNode}
          selectedEdge={selectedEdge}
        />
        <GraphEventsController
          setHoveredNode={setHoveredNode}
          setHoveredEdge={setHoveredEdge}
          setSelectedNode={setSelectedNode}
          setSelectedEdge={setSelectedEdge}
        />
        <GraphDataController
          dataset={dataset}
          questions={questions}
          setQuestions={setQuestions}
          graphStyle={graphStyle}
          currentQuestion={currentQuestion}
          filters={filtersState}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          colorize={colorize}
          setEdgesPerSynchrony={setEdgesPerSynchrony}
          data-html2canvas-ignore="false"
        />

        {/* While data is not ready, don't show graph and its controls */}
        {dataResearchReady && graphStyleReady && (
          <>
            {/* Bottom right controls, such as fullscreen, zoom and save image */}
            <div className="controls" data-html2canvas-ignore="false">
              <div className="ico">
                <button
                  type="button"
                  className="show-contents"
                  onClick={() => setShowContents(true)}
                  title="Exibir título e descrição"
                >
                  <BiBookContent />
                </button>
              </div>
              <FullScreenControl
                className="ico"
                customEnterFullScreen={
                  <BsArrowsFullscreen title="Tela inteira" />
                }
                customExitFullScreen={
                  <BsFullscreenExit title="Sair da tela inteira" />
                }
              />
              <ZoomControl
                className="ico"
                customZoomIn={<BsZoomIn title="Aproximar" />}
                customZoomOut={<BsZoomOut title="Afastar" />}
                customZoomCenter={<BiRadioCircleMarked title="Redimensionar" />}
              />
              <div className="ico">
                <button
                  type="button"
                  className=""
                  onClick={handleDownloadImage}
                  title="Salvar Imagem"
                >
                  <BsCamera />
                </button>
              </div>
              <div className="ico">
                <button
                  type="button"
                  className=""
                  onClick={reportModalToggle}
                  title="Gerar relatório complementar"
                >
                  <BsFileBarGraph />
                </button>
              </div>
              <div className="ico">
                <button
                  type="button"
                  className=""
                  onClick={configModalToggle}
                  title="Abrir configurações"
                >
                  <BsGear />
                </button>
              </div>
            </div>
            <div className="contents" data-html2canvas-ignore="false">
              <div className="ico">
                <button
                  type="button"
                  className="ico hide-contents"
                  onClick={() => setShowContents(false)}
                  title="Expandir painel"
                >
                  <GrClose />
                </button>
              </div>

              {/*
               *
               * Right Panel Open Button
               *
               */}

              <span
                style={{
                  position: "absolute",
                  top: "1em",
                  right: "1em",
                }}
              >
                <Tooltip
                  title="Abir painel de estatísticas"
                  placement="left"
                  arrow
                >
                  <div
                    id="toggle-open-panel-Right-button"
                    onClick={toggleRightPanel}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgb(250,250,250,1)",
                      width: "1em",
                      height: "1em",
                      marginLeft: "0.7rem",
                      fontSize: "3rem",
                      padding: "4px",
                      border: "1px solid rgb(250,250,250,0.7)",
                      borderRadius: "100%",
                    }}
                  >
                    <MdChevronLeft />
                  </div>
                </Tooltip>
              </span>

              {/* Top right statistics panel */}
              <RightPanel
                isOpenRightPanel={isOpenRightPanel}
                toggleRightPanel={toggleRightPanel}
              >
                {/*
                 *
                 * Right Panel Close Button
                 *
                 */}

                <span
                  style={{
                    position: "absolute",
                    top: "1em",
                    left: "1em",
                  }}
                >
                  <Tooltip title="Fechar estatísticas" placement="left" arrow>
                    <div
                      id="toggle-close-panel-right-button"
                      onClick={toggleRightPanel}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2rem",
                        padding: "4px",
                      }}
                    >
                      <MdChevronRight />
                    </div>
                  </Tooltip>
                </span>

                <StatisticsPanel
                  dataset={dataset}
                  surveyId={parseInt(surveyId)}
                  currentQuestion={currentQuestion}
                  filters={filtersState}
                  selectedNode={selectedNode}
                />
              </RightPanel>

              {/*
               *
               * Left Panel Open Button
               *
               */}

              <span
                style={{
                  position: "absolute",
                  top: "1em",
                  left: "1em",
                }}
              >
                <Tooltip title="Abrir painel" placement="right" arrow>
                  <div
                    id="toggle-panel-button"
                    onClick={toggleLeftPanel}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgb(250,250,250,1)",
                      width: "1em",
                      height: "1em",
                      marginLeft: "0.7rem",
                      fontSize: "3rem",
                      padding: "4px",
                      border: "1px solid rgb(250,250,250,0.7)",
                      borderRadius: "100%",
                    }}
                  >
                    <MdChevronRight />
                  </div>
                </Tooltip>
              </span>

              {/*
               *
               * Left Panel Content
               *
               */}

              <LeftPanel
                isOpenLeftPanel={isOpenLeftPanel}
                toggleLeftPanel={toggleLeftPanel}
              >
                {/*
                 *
                 * Left Panel Close Button
                 *
                 */}

                <span
                  style={{
                    position: "absolute",
                    top: "1em",
                    right: "1em",
                  }}
                >
                  <Tooltip title="Fechar painel" placement="right" arrow>
                    <div
                      id="toggle-panel-button"
                      onClick={toggleLeftPanel}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "2rem",
                        padding: "4px",
                      }}
                    >
                      <MdChevronLeft />
                    </div>
                  </Tooltip>
                </span>
                {/* Back Button and Logo */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {/* Logo */}
                  <img
                    className="img-fluid"
                    src={`${process.env.PUBLIC_URL}/images/neuroredes.png`}
                    alt="logo"
                    id="logo"
                  />
                  {/* Botão Voltar para pesquisas */}
                  <a
                    className="waves-effect waves-light btn-small #fafafa grey lighten-5"
                    href="/pesquisas"
                    title="Retornar para a lista de pesquisas"
                    id="bkBtn"
                    data-html2canvas-ignore="false"
                    style={{ borderRadius: "5px", marginTop: "1rem" }}
                  >
                    <i className="material-icons left">arrow_back</i>
                    Voltar para a Lista de pesquisas
                  </a>
                </div>

                <div id="subPanels" data-html2canvas-ignore="false">
                  <SearchField
                    filters={filtersState}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                  />
                  <QuestionsPanel
                    questions={dataset.questions}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                  />

                  <SynchronyPanel
                    questions={questions}
                    edgesPerSynchrony={edgesPerSynchrony}
                    synchronyStyle={graphStyle.synchrony_style}
                    filters={filtersState}
                    currentQuestion={currentQuestion}
                    setFiltersState={setFiltersState}
                  />
                  <DeptsPanel
                    depts={dataset.depts}
                    colorize={colorize}
                    graphStyle={graphStyle}
                    filters={filtersState}
                    setFiltersState={setFiltersState}
                  />
                  {/* Draw as many attribute panel as provided in the loaded data */}
                  {Array(dataset.attributes.length)
                    .fill(0)
                    .map((_, i) => {
                      return (
                        <AttributePanel
                          key={"tagspanel_" + i}
                          graphStyle={graphStyle}
                          colorize={colorize}
                          attribute={dataset.attributes[i]}
                          filters={filtersState}
                          setFiltersState={setFiltersState}
                        />
                      );
                    })}
                </div>
              </LeftPanel>
            </div>
          </>
        )}
      </SigmaContainer>
      <ConfigModal
        colorize={colorize}
        setColorize={setColorize}
        dataset={dataset}
        graphStyle={graphStyle}
        setGraphStyle={setGraphStyle}
        isConfigModalOpen={isConfigModalOpen}
        configModalToggle={configModalToggle}
      />
      <ReportModal
        isOpenReportModal={isReportModalOpen}
        toggleReportModal={reportModalToggle}
      >
        <div className="modal-head">
          <h2>Gerar relatório complementar</h2>
          <button
            style={{ cursor: "pointer", border: "none" }}
            onClick={reportModalToggle}
            title="Fechar"
          >
            <GrClose />
          </button>
        </div>
        <div className="modal-body">
          <p>
            O relatório será enviado no email{" "}
            <b>{viewer ? viewer["email"] : ""}</b> em até{" "}
            <b>{reportTimeEstimative()}</b>.
          </p>
        </div>
        <div className="modal-footer">
          <button
            className="#616161 blue darken-2 btn-small"
            onClick={() => {
              if (!viewer || !accessToken) return;
              const fileName = `Relatorio_complementar_Neuroredes_${dataset.company.replaceAll(
                " ",
                "_"
              )}_${moment().format("DD_MM_YYYY")}.xlsx`;
              API.getExcelReport(surveyId, accessToken).then((response) => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(response.data);
                link.setAttribute("download", fileName);
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                //link.click();
                // Clean up and remove the link
                document.body.removeChild(link);
              });

              toast.success("Solicitação enviada.", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
              });
              reportModalToggle();
            }}
            title="Gerar e enviar para o email"
          >
            <GrMail />
            <span style={{ marginLeft: "0.6em" }}>Gerar e enviar</span>
          </button>
          <button
            className="#616161 red darken-2 btn-small"
            style={{ cursor: "pointer", border: "none" }}
            onClick={reportModalToggle}
            title="Cancelar"
          >
            <MdOutlineCancel />
            <span style={{ marginLeft: "0.4em" }}>Cancelar</span>
          </button>
        </div>
      </ReportModal>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </div>
  );
};

export default Graph;
