import { FC } from "react";

interface Props {
  isTitle: boolean;
  isPercent: boolean;
  text: string;
  valueA: number | undefined;
  valueB: number | undefined;
}

const ComparisonTableRow: FC<Props> = ({ isTitle, isPercent, text, valueA, valueB }) => {
  return (
    <tr>
      <td>
        {isTitle ? (
          <h2>
            <b>{text}</b>
          </h2>
        ) : (
          <>{text}</>
        )}
      </td>
      <td style={{ textAlign: "center" }}>
        {valueA != null ? valueA + (isPercent?"%":"") : "Sem dados"}
      </td>
      <td style={{ textAlign: "center" }}>
        {valueB != null ? valueB + (isPercent?"%":"") : "Sem dados"}
      </td>
    </tr>
  );
};

export default ComparisonTableRow;
