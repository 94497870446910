import { KeyboardEvent, ChangeEvent, FC, useEffect, useState } from "react";
import { useSigma } from "react-sigma-v2";
import { Attributes } from "graphology-types";

import { FiltersState } from "./types";

/**
 * This component is basically a fork from React-sigma-v2's SearchControl
 * component, to get some minor adjustments:
 * 1. We need to hide hidden nodes from results
 * 2. We need custom markup
 */
const SearchField: FC<{
  filters: FiltersState;
  selectedNode: string | null;
  setSelectedNode: (node: string | null) => void;
}> = ({ filters, selectedNode, setSelectedNode }) => {
  const sigma = useSigma();

  const [search, setSearch] = useState<string>("");
  const [values, setValues] = useState<Array<{ id: string; label: string }>>(
    []
  );

  /**
   * Check the list of valid results (regex), based on the user search input.
   */
  const refreshValues = () => {
    const newValues: Array<{ id: string; label: string }> = [];
    const lcSearch = search.toLowerCase();
    if (!selectedNode && search.length >= 1) {
      sigma
        .getGraph()
        .forEachNode((key: string, attributes: Attributes): void => {
          if (!attributes.hidden && attributes.label) {
            let text: string = attributes.label.toLowerCase();
            let pattern = ".*" + lcSearch + ".*";

            // Add node if part of its label matches the search input
            if (text.match(pattern)) {
              newValues.push({ id: key, label: attributes.label });
            }

            // Add node if part of its id matches the search input
            if (attributes.key.indexOf(lcSearch) === 0) {
              newValues.push({ id: key, label: attributes.label });
            }
          }
        });
    }
    setValues(newValues);
  };

  /**
   * Refresh values when search is updated
   */
  useEffect(() => refreshValues(), [search]);

  /**
   * Refresh values when filters are updated (but wait a frame first)
   */
  useEffect(() => {
    requestAnimationFrame(refreshValues);
  }, [filters]);

  /**
   * Highlight the selected node
   */
  useEffect(() => {
    if (!selectedNode) return;
    sigma.getGraph().setNodeAttribute(selectedNode, "highlighted", true);
    return () => {
      sigma.getGraph().setNodeAttribute(selectedNode, "highlighted", false);
    };
  }, [selectedNode]);

  /**
   * Called when the search input is changed
   */
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value;
    const valueItem = values.find((value) => searchString.endsWith(value.label));
    if (valueItem) {
      setSearch(valueItem.label);
      setValues([]);
      setSelectedNode(valueItem.id);
    } else {
      setSelectedNode(null);
      setSearch(searchString);
    }
  };

  /**
   * Confirm search input when Enter is pressed
   * @param e keyboard event
   */
  const onKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && values.length) {
      setSearch(values[0].label);
      setSelectedNode(values[0].id);
    }
  };

  /**
   * Draw graphical element
   */
  return (
    <div className="input-field">
      <i className="material-icons prefix">search</i>
      <input
        id="search_graph"
        type="search"
        className="validate"
        list="nodes"
        value={search}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
      />
      <label htmlFor="search_graph">
        Procurar por nome ou número
      </label>
      <datalist id="nodes">
        {values.map((value: { id: string; label: string }) => (
          <option key={value.id} value={value.id + " - " + value.label}>
          </option>
        ))}
      </datalist>
    </div>
  );
};

export default SearchField;
